import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {DetailAppchainCard} from "../../components/deployments/DetailAppchainCard";
import {DataTable} from "../../components/core/BaseDataTable";
import {TbWorldCode} from "react-icons/tb";
import { LiaFileContractSolid } from "react-icons/lia";
import { RiRefund2Line } from "react-icons/ri";
import appchainService from "services/deployments/appchain";


const AppChainDetail = () => {

    const {projectId, appChainId} = useParams();
    const [appChain, setAppChain] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accountColumns, setAccountColumns] = useState(['address', 'private_key', 'public_key'])

    console.log(appChain)

    useEffect(() => {
        const fetchAppChain = async () => {
          try {
            const appChainObj = await appchainService.retrieve(projectId, appChainId);
            setAppChain(appChainObj);
            if (appChainObj.virtual_machine_type_display == "EVM" || appChainObj.virtual_machine_type_display == "ZKEVM"){
                setAccountColumns(['public_key', 'private_key'])
            }
          } catch (err) {
              console.log(err);
          } finally {
            setLoading(false);
          }
        };

        fetchAppChain();

    }, [projectId, appChainId]);

    return (
        <div>
            {!loading && appChain ? (
                <div className="p-6 bg-gray-100 min-h-screen pt-20" style={{ backgroundColor: '#020215' }}>
                    <DetailAppchainCard appchain={appChain}/>
                    {
                        appChain.appchain_type === "madara" ?
                        <DataTable
                            title="World Contract"
                            columns={['address']}
                            data={appChain.contract_addresses}
                            icon={TbWorldCode}
                        />
                        :
                        null
                    }
                    <DataTable
                        title="Deployed Contracts"
                        columns={['type', 'address', 'class_hash']}
                        data={appChain.contracts}
                        icon={LiaFileContractSolid}
                    />
                    <DataTable
                        title="Prefunded Accounts"
                        columns={accountColumns}
                        data={appChain.accounts}
                        icon={RiRefund2Line}
                    />
                </div>
                ) : (
                    <p>No Appchain</p>
                )
            }
        </div>

    )
}


export default AppChainDetail;