import { motion } from 'framer-motion';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import {Button} from "@tremor/react";
import React from "react";


const AddNewServicesCard = ({ appchain }) => {
  const navigate = useNavigate();

  const isDisabled = !(appchain.is_deployed && appchain.is_started);

  const handleClick = () => {
    if (!isDisabled) {
      navigate(`/appchains/${appchain.id}/services`);
    }
  };

  return (
    <motion.div
      whileTap={!isDisabled ? { scale: 0.95 } : {}}
      className={`relative bg-[#0B0B20] border ${
        isDisabled ? "border-gray-600 opacity-50 cursor-not-allowed" : "border-white/10"
      } rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 p-8`}
      aria-label="Add new services"
    >
      <div className={`absolute top-4 right-4 rounded-full p-3 shadow-lg ${isDisabled ? "" : "animate-pulse"}`}>
        <IoAddCircleOutline className={`text-4xl ${isDisabled ? "text-gray-600" : "text-white"}`} />
      </div>
      <div className="flex flex-col items-center justify-center h-full">
        <p className="mt-4 text-base text-gray-400 text-center max-w-xs leading-relaxed mb-3">
          {isDisabled
            ? "Appchain must be deployed and started to add new services."
            : "Enhance your appchain by adding essential tools such as a Block Explorer, Indexer, and Wallet integration to unlock its full potential."}
        </p>

        <Button
          variant="secondary"
          aria-label="Options"
          onClick={handleClick}
          disabled={isDisabled}
          className={`${
            isDisabled ? "bg-gray-600 text-gray-400 cursor-not-allowed" : ""
          }`}
        >
          Add new services
        </Button>
      </div>
    </motion.div>
  );
};

export default AddNewServicesCard;
