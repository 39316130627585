import {BaseService} from 'services/core/base';


class SupportService extends BaseService{
    async askSupport(request){
        const response = await this.apiClient.post("/notification/support/ask", {request});
        return response.data;
    }
}

export const supportService = new SupportService();
