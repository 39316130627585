import React, { useState } from "react";
import { isValidEmail } from "../../../core/validators/index";
import { toast } from "react-toastify";
import paymentService from "services/billing/payment";
import projectService from "services/deployments/project";
import { loadStripe } from "@stripe/stripe-js";
import { FiSend } from "react-icons/fi";
import { ImSpinner2 } from "react-icons/im";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function TeamManagement({ project, fetchProject }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const handleInvite = async () => {
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    setIsLoading(true);
    try {
      await projectService.inviteUsers(project.id, email);
      setEmail("");
      setError(null);
      fetchProject();
      toast.success("Invitation sent successfully!");
    } catch (err) {
      console.error(err);
      setError("An error occurred while sending invitations.");
      toast.error("Failed to send invitations.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = async () => {
    setIsSubscribing(true);
    try {
      const data = await paymentService.newUnlimitedUsersCheckoutSession(project.id);
      const sessionId = data.session_id;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      console.error(err);
      toast.error("Failed to create subscription session.");
    } finally {
      setIsSubscribing(false);
    }
  };

  return (
    <div className="space-y-8 max-w-2xl">
      <div className="bg-[#1A1A2E] p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold text-white">Invite Team Members</h2>
        <p className="text-sm text-gray-400 mt-2">Send an invite to your team members by entering their email address below.</p>

        <div className="mt-4 flex items-center space-x-3">
          <input
            type="email"
            disabled={!project.is_unlimited_users}
            placeholder="Enter email address"
            className="flex-grow bg-[#222244] border border-gray-600 p-3 rounded-lg text-white focus:ring-2 focus:ring-blue-600 focus:outline-none placeholder-gray-500"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-label="Email Address Input"
          />
          <button
            onClick={handleInvite}
            disabled={!project.is_unlimited_users || isLoading}
            className={`flex items-center justify-center gap-2 px-5 py-3 rounded-lg font-medium transition-all duration-200 ${
              project.is_unlimited_users
                ? "bg-blue-500 hover:bg-blue-600 text-white"
                : "bg-gray-500 text-gray-300 cursor-not-allowed"
            }`}
          >
            {isLoading ? (
              <>
                <ImSpinner2 className="animate-spin" />
                Sending...
              </>
            ) : (
              <>
                <FiSend />
                Invite
              </>
            )}
          </button>
        </div>
        {error && <p className="text-red-500 bg-red-100/10 p-3 rounded-md mt-3">{error}</p>}
      </div>

      {!project.is_unlimited_users && (
        <div className="bg-[#1A1A2E] p-6 rounded-lg text-center shadow-md">
          <p className="text-sm text-gray-400 mb-4">
            You are currently on a limited plan. Upgrade to invite unlimited users.
          </p>
          <button
            onClick={handleSubscribe}
            disabled={isSubscribing}
            className={`w-full py-3 px-6 rounded-lg font-medium transition-all duration-200 ${
              isSubscribing ? "bg-gray-400 text-white cursor-not-allowed" : "bg-[#FFC857] text-black hover:bg-[#E6B44F]"
            }`}
          >
            {isSubscribing ? "Redirecting..." : "Subscribe to Unlimited Users"}
          </button>
        </div>
      )}

      <div className="bg-[#1A1A2E] p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-white">Team Members</h3>
        <p className="text-sm text-gray-400 mt-2">Here’s a list of users who have access to the project:</p>

        <ul className="mt-4 space-y-3">
          {project.users.length > 0 ? (
            project.users.map((user) => (
              <li key={user.id} className="flex justify-between items-center bg-[#2B2B45] p-4 rounded-md">
                <div>
                  <p className="font-medium text-white">{user.email}</p>
                  <p className="text-sm text-gray-400">{user.email === project.created_by ? "Admin" : "User"}</p>
                </div>
                {user.email === project.created_by && (
                  <span className="bg-blue-600 text-white text-xs px-2 py-1 rounded-md">Owner</span>
                )}
              </li>
            ))
          ) : (
            <li className="text-center text-gray-400">No team members found.</li>
          )}
        </ul>
      </div>
    </div>
  );
}
