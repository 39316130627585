import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [isArchived, setIsArchived] = useState(false);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    onSearch(e.target.value, isArchived);
  };

  const handleCheckboxChange = (e) => {
    setIsArchived(e.target.checked);
    onSearch(query, e.target.checked);
  };

  return (
    <div className="relative w-full max-w-md">
      
      <FaSearch className="absolute left-3 top-4 transform -translate-y-1/4 text-gray-400" />
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search Projects..."
        className="w-full pl-10 pr-4 py-2 bg-[#0B0B20] border border-input-border text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-white hover:bg-[#0E0E28]"
      />
    </div>
  );
};

export default SearchBar;
