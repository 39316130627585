import React from 'react';

import { useNavigate } from 'react-router-dom';
import PrimaryButton from "../PrimaryButton";


const ProjectSettingsButton = ({ projectId }) => {

  const navigate = useNavigate();

  return (
    <div>
      <PrimaryButton
        onClick={ () => navigate(`/projects/settings/${projectId}`)}
        className={`w-full py-3 px-6 rounded-lg font-medium transition-all duration-200`}
      >
        Settings
      </PrimaryButton>
    </div>
  );
};

export default ProjectSettingsButton;