import React from 'react';
import DeleteArchiveProject from './DeleteArchiveProject';
import Modal from 'react-modal';
import { motion, AnimatePresence } from 'framer-motion';
import { IoMdClose } from 'react-icons/io';

const DeleteArchiveProjectModal = ({ isOpen, onRequestClose, projectId, action, handleCancelSubscription, handleDeleteProject}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          className="fixed inset-0 flex items-center justify-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75"
        >
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className="bg-[#1C1C3A] text-white p-8 rounded-lg shadow-lg max-w-md w-full relative"
          >
            <button
              onClick={onRequestClose}
              className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors"
              aria-label="Close modal"
            >
              <IoMdClose size={24} />
            </button>
            <h2 className="text-2xl mb-5 text-center font-thin">
              {action === "delete" ? (
                <> Confirm Delete</>
              ) : (
                <>Confirm Cancel Subscription</>
              )}
            </h2>
            <DeleteArchiveProject
              onRequestClose={onRequestClose}
              projectId={projectId}
              action={action}
              handleCancelSubscription={handleCancelSubscription}
              handleDeleteProject={handleDeleteProject}
            />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default DeleteArchiveProjectModal;
