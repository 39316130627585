import React, { useState } from 'react';
import { FaTelegram, FaDiscord } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { supportService } from 'services/notification/support';
import PrimaryButton from 'components/PrimaryButton';

const SupportRequestPage = () => {
  const [state, setState] = useState({
    request: '',
    loading: false,
    hasSubmitted: false,
    error: '',
  });

  const updateState = (updates) => setState((prev) => ({ ...prev, ...updates }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateState({ loading: true, error: '' });

    try {
      await supportService.askSupport(state.request);
      updateState({ hasSubmitted: true, request: '' });
    } catch {
      updateState({
        error: "We encountered an issue. Please try again or reach us via Telegram or Discord.",
      });
    } finally {
      updateState({ loading: false });
    }
  };

  return (
    <div className="min-h-screen bg-[#020215] text-white flex flex-col items-center justify-center px-6 py-2">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-3xl bg-[#0A0A22] border border-[#333355] shadow-lg rounded-lg p-8"
      >
        <HeaderSection />
        <SocialLinksSection />
        {!state.hasSubmitted ? (
          <FormSection
            state={state}
            onChange={(value) => updateState({ request: value })}
            onSubmit={handleSubmit}
          />
        ) : (
          <SuccessMessage />
        )}
      </motion.div>
    </div>
  );
};

const HeaderSection = () => (
  <div className="text-center mb-8">
    <h1 className="text-4xl font-bold mb-2">Support Request</h1>
    <p className="text-gray-400">
      Need assistance? Fill out the form below or reach out to us via Telegram or Discord.
    </p>
  </div>
);

const SocialLinksSection = () => (
  <div className="flex justify-center gap-6 mb-8">
    <SocialLink
      href="https://t.me/+lZdhK9AJd2UzMmZh"
      icon={FaTelegram}
      label="Telegram"
    />
    <SocialLink
      href="https://discord.gg/5BSWHGnpFA"
      icon={FaDiscord}
      label="Discord"
    />
  </div>
);

const SocialLink = ({ href, icon: Icon, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center gap-2 px-4 py-2 bg-[#232347] hover:bg-[#2D2D56] rounded-full text-[#FFC857] transition"
  >
    <Icon className="text-xl" />
    {label}
  </a>
);

const FormSection = ({ state, onChange, onSubmit }) => (
  <form onSubmit={onSubmit} className="space-y-6">
    {state.error && <FeedbackMessage type="error">{state.error}</FeedbackMessage>}
    <div>
      <label htmlFor="request" className="block text-sm text-gray-400 mb-2">
        Your Message
      </label>
      <textarea
        id="request"
        className="w-full bg-[#0F0F2D] border border-gray-600 text-gray-300 rounded-lg p-4 focus:ring-2 focus:outline-none"
        placeholder="Describe your issue or request..."
        rows={6}
        value={state.request}
        onChange={(e) => onChange(e.target.value)}
        required
      />
    </div>
    <PrimaryButton
      type="submit"
      className={`w-full h-12 rounded-lg font-semibold ${
        state.loading ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      disabled={state.loading}
    >
      {state.loading ? 'Submitting...' : 'Submit Request'}
    </PrimaryButton>
  </form>
);

const FeedbackMessage = ({ type, children }) => {
  const color = type === 'error' ? 'text-red-500' : 'text-green-500';
  return <p className={`text-sm ${color} mt-2`}>{children}</p>;
};

const SuccessMessage = () => (
  <div className="text-center">
    <h2 className="text-2xl font-semibold text-green-500 mb-4">
      Thank you for your request!
    </h2>
    <p className="text-gray-400">
      Our support team will contact you as soon as possible.
    </p>
  </div>
);

export default SupportRequestPage;
