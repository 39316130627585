import {BaseService} from "../core/base";
import {applyMixin} from "../../core/reducers/applyMixin";
import {DeployableServiceMixin} from "./deployableMixin";


class BlockScout extends BaseService{

    baseUri = "/deployments/projects/<projectId>/block_scout/";

    getUri(projectId){
        return this.baseUri.replace("<projectId>", projectId);
    }

    // async list(projectId){
    //     const response = await this.apiClient.get(this.getUri(projectId));
    //     return response.data;
    // }

    // async retrieve(projectId, katanaAppId){
    //     let finalUri = this.getUri(projectId) + katanaAppId + "/";
    //     const resp = await this.apiClient.get(finalUri);
    //     return resp.data;
    // }
}


export const blockScoutService = new BlockScout();
applyMixin(blockScoutService, DeployableServiceMixin);

export default blockScoutService;
