import { applyMixin } from "core/reducers/applyMixin";
import {BaseService} from "../core/base";
import { DeployableServiceMixin } from "./deployableMixin";
import { FaucetMixin } from "./faucetMixin";

class AppchainService extends BaseService{
    baseUri = "/deployments/projects/<projectId>/appchains/";

    async listAll(){
        const resp = await this.apiClient.get("/deployments/appchains");
        return resp.data;
    }

    getUri(projectId){
        return this.baseUri.replace("<projectId>", projectId);
    }

    async getAvailableServices(appchainId){
        const resp = await this.apiClient.get("/deployments/appchains/" + appchainId + "/services");
        return resp.data;
    }
}

const appchainService = new AppchainService();
applyMixin(appchainService, DeployableServiceMixin);
applyMixin(appchainService, FaucetMixin);

export default appchainService;
