import React, {useState} from "react";
import FaucetComponent from "components/deployments/Faucet";
import AddChainMetaMask from "components/deployments/AddChainMetaMask";
import { ethers } from "ethers";

export default function EthereumConfigContainer({ appChain }) {

  const [address, setAddress] = useState(null);
  const [balance, setBalance] = useState(null);

  const chainData = {
    chainId: `0x${parseInt(appChain.metadata.chain_id, 10).toString(16)}`, // Convert to hex
    chainName: appChain.project_name,
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [`https://${appChain.url}`],
  };

  // Fetch Balance
  const fetchBalance = async (userAddress) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(chainData.rpcUrls[0]);
      const balance = await provider.getBalance(userAddress);
      setBalance(ethers.utils.formatEther(balance)); // Convert Wei to Ether
    } catch (err) {
      console.error("Error fetching balance:", err);
    }
  };

  return (
    <div>
      <FaucetComponent appChain={appChain} address={address} fetchBalance={fetchBalance}/>
      <div className="mt-4">
        <AddChainMetaMask 
          appChain={appChain} 
          address={address} 
          setAddress={setAddress} 
          balance={balance} 
          setBalance={setBalance} 
          fetchBalance={fetchBalance}
          chainData={chainData}
        />
      </div>
    </div>
  );
}
