import React, { useState } from 'react';
import loader from '../../imgs/icons/loader.svg';
import {IoIosPause, IoIosPlay, IoMdEye} from "react-icons/io";
import { FaEllipsisV, FaRegTrashAlt } from 'react-icons/fa';
import { GrPowerReset } from 'react-icons/gr';
import { Button } from "@tremor/react";
import DropdownButton from "../ui/DropdownButton";
import {motion, AnimatePresence} from "framer-motion";


const PlayPauseController = ({ appchain, tool, deployTool, stopTool, startTool }) => {
    let playContent;

    if (!tool.is_deployed && appchain.is_deployed && !tool.is_deploying && tool.status !== "Error") {
        playContent = (
            <DropdownButton onClick={deployTool} icon={IoIosPlay}>
                Deploy
            </DropdownButton>
        );
    } else if (tool.is_deploying && !tool.is_started  && tool.status !== "Error") {
        playContent = <img src={loader} alt='loading' width="35" />;
    } else if (appchain.is_deployed  && tool.status !== "Error") {
        if (tool.is_started) {
            playContent = (
                <DropdownButton onClick={stopTool} icon={IoIosPause}>
                    Pause
                </DropdownButton>
            );
        } else {
            playContent = (
                <DropdownButton onClick={startTool} icon={IoIosPlay}>
                    Start
                </DropdownButton>
            );
        }
    } else {
        return null
    }

    return playContent;
};


const ServiceControl = ({ appchain, tool, deployTool, stopTool, startTool, restartTool, deleteTool, navigateToServiceLogs})=> {

    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <div className="relative inline-block text-left">
                <Button
                    onClick={() => setShowDropdown(!showDropdown)}
                    icon={FaEllipsisV}
                    variant="secondary"
                    className='w-10 h-10 flex text-white items-center justify-center rounded-full bg-[#1C1C3A] hover:bg-[#29294b] transition-all duration-200 ease-in-out'
                    aria-label="Options"
                />
                <AnimatePresence>
                    {showDropdown && (
                        <motion.div
                              className='absolute right-0 mt-2 bg-[#0F0F2D] border border-white/10 rounded-lg shadow-lg p-2 z-50'
                              initial={{ opacity: 0, y: -10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                              transition={{ duration: 0.2 }}
                            >
                            <PlayPauseController appchain={appchain} tool={tool} deployTool={deployTool} stopTool={stopTool} startTool={startTool}/>
                            {tool.status === "Error" && (
                                <>
                                    <DropdownButton onClick={() => {deleteTool(); setShowDropdown(false)}} icon={FaRegTrashAlt}>
                                        Delete
                                    </DropdownButton>
                                </>
                            )}
                            {tool.is_deployed && (
                                <>
                                    <DropdownButton onClick={restartTool} icon={GrPowerReset}>
                                        Restart
                                    </DropdownButton>
                                    <DropdownButton onClick={navigateToServiceLogs} icon={IoMdEye}>
                                        Logs
                                    </DropdownButton>
                                    <DropdownButton onClick={() => {deleteTool(); setShowDropdown(false)}} icon={FaRegTrashAlt}>
                                        Delete
                                    </DropdownButton>
                                </>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
    )
}


export default ServiceControl;
