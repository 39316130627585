import {BaseService} from "../core/base";

class PaymentService extends BaseService{
    
    async sendStripeIntent(plan){
        const resp = await this.apiClient.post(
            "/billing/payments/stripe", {plan}
        )
        return resp.data;
    }

    async sendCryptoPayment(amount, transactionHash, planType){
        const resp = await this.apiClient.post("/billing/payments/crypto", 
        {
            "transaction_hash": transactionHash,
            "plan": planType,
            "amount": amount
        })
        return resp.data
    }

    async getPayment(id){
        const resp = await this.apiClient.get(`/billing/payments/crypto/${id}`)
        return resp.data
    }

    async newProjectCheckoutSession(projectId, isUpgrade=false){
        const resp = await this.apiClient.post(
            "/billing/projects/" + projectId + "/psp-sessions?upgrade=" + isUpgrade
        );
        return resp.data;
    }

    async newUnlimitedUsersCheckoutSession(projectId){
        const resp = await this.apiClient.post(
            "/billing/projects/" + projectId + "/users"
        );
        return resp.data;
    }
}

const paymentService = new PaymentService();
export default paymentService;
