import { CheckedInput, TitleInput, TextLeftInput } from "components/deployments/LaunchInputs"

import Timer from "../../../imgs/icons/Timer.svg"
import Plant from "../../../imgs/icons/Plant.svg"
import UserList from "../../../imgs/icons/UserList.svg"
import Link from "../../../imgs/icons/Link.svg"
import Info from "../../../imgs/icons/Info.svg"

import { TEN_PERCENT_BORDER } from "../../styles/styles"


const StacksSpecs = ({metaData, setMetaData})=>{

    const handleInputChange = (key, value) => {
        setMetaData({
            ...metaData,
            [key]: value
        });
    };

    return (
        <div className="mt-14 flex flex-col items-center text-center">
            <div className=" text-white py-8 px-6 rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold">Coming Soon!</h1>
                <p className="mt-4 text-lg">
                    We're working hard to bring you Specs for this stack!
                </p>
            </div>
        </div>
    );
    
}

export default StacksSpecs