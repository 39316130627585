import {BaseService} from "../core/base";
import {applyMixin} from "../../core/reducers/applyMixin";
import {DeployableServiceMixin} from "./deployableMixin";

export class ToriiService extends BaseService{

    baseUri = "/deployments/projects/<projectId>/torii/";

    getUri(projectId){
        return this.baseUri.replace("<projectId>", projectId);
    }
}


const toriiService = new ToriiService();

applyMixin(toriiService, DeployableServiceMixin)
export default toriiService;