import React, {useState} from 'react';
import {Link} from "react-router-dom";
import Info from "../../imgs/icons/Info.svg";
import {FaEdit, FaCheckCircle, FaUserAlt, FaDollarSign} from "react-icons/fa";
import {MdOutlineDescription} from "react-icons/md";
import {motion, AnimatePresence} from 'framer-motion';
import {BORDER_CLASS, Button, Tooltip} from "components/deployments/LaunchInputs";

import useLaunchAppchain from 'hooks/deployments/uselaunchappchain';

import ArbitrumSpecs from 'components/deployments/specs/Arbitrum';
import AnvilSpecs from 'components/deployments/specs/Anvil';
import AztecSpecs from 'components/deployments/specs/Aztec';
import MadaraSpecs from 'components/deployments/specs/MadaraSpecs';
import OptimismSpecs from 'components/deployments/specs/Optimism';
import ScrollSpecs from 'components/deployments/specs/Scroll';
import StacksSpecs from 'components/deployments/specs/Stacks';
import ZkSyncSpecs from 'components/deployments/specs/ZkSync';
import EthereumServices from "containers/EthereumServices";
import StarknetServices from 'containers/StarknetServices';


export default function LaunchAppchain() {
    const {
        selectedEnvironment, onEnvironmentChange,
        selectedFramework, selectedSettlementLayer, projectName, setProjectName,
        setProjectVisibility, projectVisibilities, metaData, setMetaData,
        error, setError,
        summaryItems, handleSubmit, selectedServices, setSelectedServices, isSubmitting,
        changeAppchainFramework, changeSettlementLayer, appchainFrameworkData, settlementLayers
    } = useLaunchAppchain();

    const [isHovered, setHovered] = useState(false);
    const [validProjectName, setValidProjectName] = useState(null);

    const hasServices = () => {
        return Object.values(selectedServices).some((val) => val);
    }

    const validateProjectName = (name) => {
        const isValid = name.length >= 3;
        setValidProjectName(isValid);
        return isValid;
    };

    const handleProjectNameChange = (e) => {
        const name = e.target.value;
        setProjectName(name);
        validateProjectName(name);
    };

    const handleServiceToggle = (service) => {
        setSelectedServices(prev => ({...prev, [service]: !prev[service]}));
    };

    return (
        <div className="container mx-auto px-4 py-8 relative">
            <div className="mt-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div className="lg:col-span-2">
                    <SectionTitle title="Build Your Appchain"/>
                    <div className="bg-[#0A0A22] rounded-lg p-6 border border-white/15">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <ProjectNameInput
                                projectName={projectName}
                                onChange={handleProjectNameChange}
                                isValid={validProjectName}
                            />
                            <VisibilitySelector
                                visibilities={projectVisibilities}
                                onChange={setProjectVisibility}
                            />
                        </div>
                        <EnvironmentSetting
                            selectedEnvironment={selectedEnvironment}
                            onEnvironmentChange={onEnvironmentChange}
                        />
                        <FrameworkButtons
                            frameworks={appchainFrameworkData}
                            selectedFramework={selectedFramework}
                            onFrameworkChange={changeAppchainFramework}
                        />
                        <SettlementLayerButtons
                            settlementLayers={settlementLayers}
                            selectedSettlementLayer={selectedSettlementLayer}
                            onSettlementLayerChange={changeSettlementLayer}
                        />
                        {error?.metadata && <ErrorMessage message={error.metadata}/>}
                        <SpecsSection
                            selectedFramework={selectedFramework}
                            metaData={metaData}
                            setMetaData={setMetaData}
                        />
                        <ServiceSection
                            selectedFramework={selectedFramework}
                            selectedServices={selectedServices}
                            onServiceToggle={handleServiceToggle}
                        />
                    </div>
                </div>
                <SummarySection
                    selectedEnvironment={selectedEnvironment}
                    summaryItems={summaryItems}
                    validProjectName={validProjectName}
                    isSubmitting={isSubmitting}
                    handleSubmit={handleSubmit}
                    isHovered={isHovered}
                    setHovered={setHovered}
                    hasServices={hasServices()}
                />
            </div>
        </div>
    );
}


const SectionTitle = ({title}) => (
    <h2 className="text-3xl font-semibold text-white mb-6">{title}</h2>
);

const ProjectNameInput = ({projectName, onChange, isValid}) => (
    <div className="mb-6">
        <label htmlFor="projectName" className="block text-normal font-thin text-white mb-2">
            Name your project
        </label>
        <div className="relative">
            <input
                id="projectName"
                placeholder="Project Name"
                onChange={onChange}
                className={`bg-[#0F0F2D] border ${isValid === false ? 'border-red-500' : 'border-white/10'} text-white w-full p-3 pr-10 rounded-md focus:ring-blue-500`}
                defaultValue={projectName}
            />
            <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FaEdit className="text-white"/>
            </span>
        </div>
        {isValid === false && <p className="text-red-500 text-sm mt-1">Project name is too short.</p>}
    </div>
);

const VisibilitySelector = ({visibilities, onChange}) => (
    <div className="mb-6">
        <label htmlFor="visibility" className="block text-normal font-thin text-white mb-2">
            Choose the visibility of your project
        </label>
        <select
            id="visibility"
            onChange={(e) => onChange(e.target.value)}
            className="w-full p-3 bg-[#0F0F2D] text-white border border-white/10 rounded-md"
        >
            {visibilities.map(({label, value}) => (
                <option key={value} value={value}>{label}</option>
            ))}
        </select>
    </div>
);

const EnvironmentSetting = ({selectedEnvironment, onEnvironmentChange}) => (
    <>
        <label htmlFor="visibility" className="block text-normal font-thin text-white mb-2">
            Project Environment
        </label>
        <div className="grid grid-cols-2 sm:grid-cols-2 gap-4 mb-6">
            <button
                key={"staging"}
                onClick={() => onEnvironmentChange("staging")}
                className={`flex items-center space-x-2 px-4 py-2 rounded ${BORDER_CLASS} text-sm w-full flex justify-between 
                ${selectedEnvironment === "staging"
                    ? "border-white/80" : "border-white/10"}`}
            >
                <div>Staging</div>
                <Tooltip
                    icon={Info}
                    description={"Live Optimized Staging Environment"}
                />
            </button>
            <button
                key={"production"}
                onClick={() => onEnvironmentChange("production")}
                className={`flex items-center space-x-2 px-4 py-2 rounded ${BORDER_CLASS} text-sm w-full flex justify-between 
                ${selectedEnvironment === "production"
                    ? "border-white/80" : "border-white/10"}`}
            >
                <div>Production</div>
                <Tooltip
                    icon={Info}
                    description={"Mainnet Environment (on-demand, please book a call with our experts)"}
                />
            </button>

        </div>
    </>

);

const FrameworkButtons = ({frameworks, selectedFramework, onFrameworkChange}) => (
    <>
        <label htmlFor="visibility" className="block text-normal font-thin text-white mb-2">
            Choose your Appchain Framework
        </label>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-6">
            {frameworks.map(({text, logo, framework_key, tooltip_text, ideal_for, features}) => (
                <Button
                    key={framework_key}
                    text={text}
                    logo={logo}
                    onClick={() => onFrameworkChange(framework_key)}
                    className={`text-sm w-full flex justify-between ${selectedFramework === framework_key ? "border-white/80" : "border-white/10"}`}
                >
                    <Tooltip
                        icon={Info}
                        description={tooltip_text}
                        idealFor={ideal_for}
                        features={features}
                    />
                </Button>
            ))}
        </div>
    </>

);


const SettlementLayerButtons = ({settlementLayers, selectedSettlementLayer, onSettlementLayerChange}) => (
    <>
        <label htmlFor="visibility" className="block text-normal font-thin text-white mb-2">
            Choose your SettlementLayer
        </label>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-6">
            {settlementLayers.map(({text, logo, frameworkKey, tooltipText, idealFor, features}) => (
                <Button
                    key={frameworkKey}
                    text={text}
                    logo={logo}
                    onClick={() => onSettlementLayerChange(frameworkKey)}
                    className={`text-sm w-full flex justify-between ${selectedSettlementLayer === frameworkKey ? "border-white/80" : "border-white/10"}`}
                >
                    <Tooltip
                        icon={Info}
                        description={tooltipText}
                        idealFor={idealFor}
                        features={features}
                    />
                </Button>
            ))}
        </div>
    </>

);


const GasTokenForm = ({onDeploy}) => {
    const [tokenDetails, setTokenDetails] = useState({
        name: "",
        symbol: "",
        decimals: 18,
        totalSupply: "",
        mintTo: "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setTokenDetails((prev) => ({...prev, [name]: value}));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onDeploy(tokenDetails);
    };

    return (
        <>
            <label htmlFor="visibility" className="block text-normal font-thin text-white mb-2">
                Create Gas Token
            </label>
            <div className="bg-[#0A0A22] p-6 rounded-lg shadow-md">
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Token Name */}
                    <div className="flex items-center bg-[#0F0F2D] rounded-md p-2">
                        <FaUserAlt className="text-gray-400 mr-3"/>
                        <input
                            type="text"
                            name="name"
                            placeholder="Token Name"
                            value={tokenDetails.name}
                            onChange={handleChange}
                            className="w-full bg-transparent text-white focus:outline-none"
                        />
                    </div>
                    {/* Token Symbol */}
                    <div className="flex items-center bg-[#0F0F2D] rounded-md p-2">
                        <FaDollarSign className="text-gray-400 mr-3"/>
                        <input
                            type="text"
                            name="symbol"
                            placeholder="Token Symbol"
                            value={tokenDetails.symbol}
                            onChange={handleChange}
                            className="w-full bg-transparent text-white focus:outline-none"
                        />
                    </div>
                    {/* Decimals */}
                    <div className="flex items-center bg-[#0F0F2D] rounded-md p-2">
                        <MdOutlineDescription className="text-gray-400 mr-3"/>
                        <input
                            type="number"
                            name="decimals"
                            placeholder="Decimals (default: 18)"
                            value={tokenDetails.decimals}
                            onChange={handleChange}
                            className="w-full bg-transparent text-white focus:outline-none"
                        />
                    </div>
                    {/* Total Supply */}
                    <div className="flex items-center bg-[#0F0F2D] rounded-md p-2">
                        <FaDollarSign className="text-gray-400 mr-3"/>
                        <input
                            type="text"
                            name="totalSupply"
                            placeholder="Total Supply"
                            value={tokenDetails.totalSupply}
                            onChange={handleChange}
                            className="w-full bg-transparent text-white focus:outline-none"
                        />
                    </div>
                    {/* Mint To Address */}
                    <div className="flex items-center bg-[#0F0F2D] rounded-md p-2">
                        <FaUserAlt className="text-gray-400 mr-3"/>
                        <input
                            type="text"
                            name="mintTo"
                            placeholder="Initial Mint Address"
                            value={tokenDetails.mintTo}
                            onChange={handleChange}
                            className="w-full bg-transparent text-white focus:outline-none"
                        />
                    </div>
                </form>
            </div>
        </>

    );
};


const SpecsSection = ({selectedFramework, metaData, setMetaData}) => {

    switch (selectedFramework) {
        case "arbitrum":
            return <ArbitrumSpecs metaData={metaData} setMetaData={setMetaData}/>
        case "authenticated_madara":
            return <MadaraSpecs metaData={metaData} setMetaData={setMetaData}/>;
        case "anvil":
            return <AnvilSpecs metaData={metaData} setMetaData={setMetaData}/>;
        case "aztec":
            return <AztecSpecs metaData={metaData} setMetaData={setMetaData}/>;
        case "madara":
            return <MadaraSpecs metaData={metaData} setMetaData={setMetaData}/>;
        case "optimism":
            return <OptimismSpecs metaData={metaData} setMetaData={setMetaData}/>
        case "scroll":
            return <ScrollSpecs metaData={metaData} setMetaData={setMetaData}/>
        case "stacks":
            return <StacksSpecs metaData={metaData} setMetaData={setMetaData}/>
        case "zksync":
            return <ZkSyncSpecs metaData={metaData} setMetaData={setMetaData}/>;
        default:
            return <p>not supported</p>
    }
};

const ServiceSection = ({selectedFramework, selectedServices, onServiceToggle}) => {

    switch (selectedFramework) {
        case "arbitrum":
            return <EthereumServices selectedServices={selectedServices} handleServiceToggle={onServiceToggle}/>
        case "authenticated_madara":
            return <StarknetServices selectedServices={selectedServices} handleServiceToggle={onServiceToggle}/>;
        case "anvil":
            return <EthereumServices selectedServices={selectedServices} handleServiceToggle={onServiceToggle}/>;
        case "aztec":
            return <></>;
        case "madara":
            return <StarknetServices selectedServices={selectedServices} handleServiceToggle={onServiceToggle}/>;
        case "optimism":
            return <EthereumServices selectedServices={selectedServices} handleServiceToggle={onServiceToggle}/>
        case "scroll":
            return <EthereumServices selectedServices={selectedServices} handleServiceToggle={onServiceToggle}/>
        case "stacks":
            return <></>;
        case "zksync":
            return <EthereumServices selectedServices={selectedServices} handleServiceToggle={onServiceToggle}/>
        default:
            return <p>Not Supported</p>;
    }


};

const ErrorMessage = ({message}) => (
    <div className="bg-red-500 text-white p-4 rounded-md mb-4">
        <p>{message}</p>
    </div>
);

const SummarySection = ({
  selectedEnvironment,
  summaryItems,
  validProjectName,
  isSubmitting,
  handleSubmit,
  hasServices,
  isHovered,
  setHovered,
}) => {
  const getDetails = () => {
    return selectedEnvironment === "staging"
      ? [
          { label: "Deployment Cost", value: "100% Free", icon: "💰" },
          { label: "Plan Cost", value: `From just ${hasServices ? "$79.99": "$49.99"}/month`, icon: "📅" },
          { label: "Your Savings", value: "Countless hours saved", icon: "⏳" },
          { label: "Benefits", value: "Optimized Dev Environment", icon: "🧠" },
        ]
      : [
          { label: "Deployment Cost", value: "Custom Quotation", icon: "💰" },
          { label: "Plan Cost", value: "Tailored for your needs", icon: "📅" },
          { label: "Your Savings", value: "Countless hours saved", icon: "⏳" },
          { label: "Benefits", value: "Optimized Prod Environment", icon: "🧠" },
        ];
  };

  return (
    <div className="lg:col-span-1">
      <SectionTitle title={"Deployment Summary"} />
      <div className="bg-[#0F0F2D] border border-gray-800 rounded-lg p-6 shadow-lg">
        <h4 className="text-2xl text-white mb-4">Here’s What You’ve Chosen</h4>
        <AnimatePresence>
          {summaryItems.map((summary, idx) => (
            <motion.div
              key={idx}
              initial={{ opacity: 0, translateY: -10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: -10 }}
              className="flex items-center mb-4 pb-2 border-b border-gray-600 last:border-none"
            >
              <FaCheckCircle className="text-green-500 mr-3" />
              <p className="text-white/90 font-medium">{summary}</p>
            </motion.div>
          ))}
        </AnimatePresence>

        <h5 className="text-xl mt-6 mb-4 text-white">Costs and Benefits</h5>
        {getDetails().map(({ label, value, icon }, idx) => (
          <div
            key={idx}
            className="flex justify-between items-center mb-3 pb-3 border-b border-gray-700 last:border-none"
          >
            <div className="flex items-center gap-2">
              <span className="text-white text-lg">{icon}</span>
              <p className="text-[#B2B2C0]">{label}</p>
            </div>
            <p className="text-white font-semibold">{value}</p>
          </div>
        ))}

        <div className="relative mt-8">
          {selectedEnvironment === "staging" ? (
            <button
              className={`w-full py-3 rounded-lg font-bold ${
                !validProjectName
                  ? "bg-gray-400 text-gray-600 cursor-not-allowed"
                  : "bg-[#FFC857] text-black hover:bg-[#E6B44F] hover:text-gray-900"
              } transition-all duration-200`}
              onClick={handleSubmit}
              disabled={!validProjectName || isSubmitting}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              {isSubmitting ? "Launching your appchain..." : "Launch Your Appchain 🚀"}
            </button>
          ) : (
            <button className="w-full py-3 rounded-lg bg-white text-black font-semibold hover:bg-gray-800 hover:text-white transition-all duration-200"
                onClick={() => window.location.href = process.env.REACT_APP_BOOK_CALL_LINK}
            >
              <a
                href={process.env.REACT_APP_BOOK_CALL_LINK}
                target="_blank"
                rel="noreferrer"
              >
                Schedule a Call and Launch 🚀
              </a>
            </button>
          )}
          {!validProjectName && isHovered && (
            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 w-max p-2 text-xs text-black bg-white rounded-lg shadow-lg z-10">
              Don't forget to name your project!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};