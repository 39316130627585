import { BaseService } from "../core/base";

class AppchainFrameworkService extends BaseService{
    
    async getAppchainFrameworks(){
        const resp = await this.apiClient.get(
            "/refs/appchain-frameworks"
        )
        return resp.data;
    }
}

const appchainFrameworkService = new AppchainFrameworkService();
export default appchainFrameworkService;
