import React, { useState } from "react";
import projectService from "../../../services/deployments/project";
import DeleteArchiveProjectModal from "components/deployments/DeleteArchiveProjectModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ManageProjectSubscription = ({ project, fetchProject }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [action, setAction] = useState("archive");
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [cancelConfirmationText, setCancelConfirmationText] = useState("");
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");
  const navigate = useNavigate();

  // Close the final modal
  const closeModal = () => {
    setModalIsOpen(false);
    // Reset friction states so that each new action starts fresh
    setShowCancelConfirmation(false);
    setShowDeleteConfirmation(false);
    setCancelConfirmationText("");
    setDeleteConfirmationText("");
  };

  const handleCancelSubscription = async () => {
    setIsLoading(true);
    try {
      await projectService.cancelSubscription(project.id);
      fetchProject(); // Refresh project data
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
      toast.error("Failed to cancel subscription:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProject = async () => {
    setIsLoading(true);
    try {
      // You mentioned that deleting the project also cancels the subscription
      await projectService.cancelSubscription(project.id);
      await projectService.delete(project.id);
      navigate("/");
    } catch (error) {
      console.error("Failed to delete project:", error);
      toast.error("Failed to delete project:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!project.is_subscribed) {
    return (
      <>
        <ManageProjectDeletion 
          project={project}
          showDeleteConfirmation={showDeleteConfirmation}
          isLoading={isLoading}
          deleteConfirmationText={deleteConfirmationText} 
          setDeleteConfirmationText={setDeleteConfirmationText} 
          setShowDeleteConfirmation={setShowDeleteConfirmation}
          setAction={setAction}
          setModalIsOpen={setModalIsOpen}
        />
        {modalIsOpen && (
          <DeleteArchiveProjectModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            projectId={project.id}
            action={action}
            handleCancelSubscription={handleCancelSubscription}
            handleDeleteProject={handleDeleteProject}
          />
        )}
      </>

    );

  }

  return (
      <div className="bg-[#0B0B20] p-6 rounded-md shadow-md">
        <CancelProjectSubscription
          project={project}
          isLoading={isLoading}
          setAction={setAction}
          setModalIsOpen={setModalIsOpen}
          showCancelConfirmation={showCancelConfirmation}
          setShowCancelConfirmation={setShowCancelConfirmation}
          cancelConfirmationText={cancelConfirmationText}
          setCancelConfirmationText={setCancelConfirmationText}
        />
          <ManageProjectDeletion 
            project={project}
            showDeleteConfirmation={showDeleteConfirmation}
            isLoading={isLoading}
            deleteConfirmationText={deleteConfirmationText} 
            setDeleteConfirmationText={setDeleteConfirmationText} 
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            setAction={setAction}
            setModalIsOpen={setModalIsOpen}
          />
          {modalIsOpen && (
              <DeleteArchiveProjectModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                projectId={project.id}
                action={action}
                handleCancelSubscription={handleCancelSubscription}
                handleDeleteProject={handleDeleteProject}
              />
            )}
      </div>
  );
};


const CancelProjectSubscription = ({
  project, 
  isLoading, 
  setAction, 
  setModalIsOpen,
  showCancelConfirmation,
  setShowCancelConfirmation,
  cancelConfirmationText,
  setCancelConfirmationText
})=>{
  return (
    <div className="bg-[#0B0B20] p-6 rounded-md shadow-md mt-4">
    <h2 className="text-xl font-semibold mb-4">Manage Subscription</h2>
        <p className="mb-4 text-gray-400">
          Cancel your subscription to stop recurring charges for this project.
          <strong> This action cannot be undone.</strong>
        </p>

        {/* Step 1: Show a friction layer for canceling the subscription. */}
        {!showCancelConfirmation ? (
          <button
            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded disabled:opacity-50"
            onClick={() => setShowCancelConfirmation(true)}
            disabled={isLoading}
          >
            {isLoading ? "Canceling..." : "Cancel Subscription"}
          </button>
        ) : (
          <div className="mt-4 p-4 border border-red-600 rounded">
            <p className="text-red-400 font-semibold">
              Please type <strong>CANCEL</strong> to confirm you want to cancel
              the subscription for <strong>{project.name}</strong>.
            </p>
            <input
              type="text"
              className="bg-[#12122A] mt-2 p-2 rounded border border-gray-600 w-full focus:outline-none focus:border-red-500"
              placeholder="Type CANCEL to confirm"
              value={cancelConfirmationText}
              onChange={(e) => setCancelConfirmationText(e.target.value)}
            />
            <div className="mt-4 flex gap-2">
              <button
                className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded disabled:opacity-50"
                disabled={
                  isLoading || cancelConfirmationText.trim() !== "CANCEL"
                }
                onClick={() => {
                  // Proceed to final step: open modal for final action
                  setAction("cancel");
                  setModalIsOpen(true);
                }}
              >
                Confirm Cancel
              </button>
              <button
                className="bg-gray-600 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded"
                onClick={() => {
                  setShowCancelConfirmation(false);
                  setCancelConfirmationText("");
                }}
              >
                Go Back
              </button>
            </div>
          </div>
          
        )}
    
    </div>
  )
}


const ManageProjectDeletion = ({
    project, 
    showDeleteConfirmation, 
    isLoading, 
    deleteConfirmationText, 
    setDeleteConfirmationText, 
    setShowDeleteConfirmation, 
    setAction, 
    setModalIsOpen
}) => {
  return (
    <div className="bg-[#0B0B20] p-6 rounded-md shadow-md mt-4">
    <h2 className="text-xl font-semibold mb-4">Delete Project</h2>
    <p className="mb-4 text-gray-400">
      Deleting your project will remove your appchains, services,
      <em> and cancel your subscription.</em> 
      <strong> This action cannot be undone.</strong>
    </p>

    {/* Step 1: Show a friction layer for deleting the project. */}
    {!showDeleteConfirmation ? (
      <button
        className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded disabled:opacity-50"
        disabled={isLoading}
        onClick={() => setShowDeleteConfirmation(true)}
      >
        {isLoading ? "Deleting..." : "Delete Project"}
      </button>
    ) : (
      <div className="mt-4 p-4 border border-red-600 rounded">
        <p className="text-red-400 font-semibold">
          Please type the name of your project (
          <strong>{project.name}</strong>) to confirm deletion.
        </p>
        <input
          type="text"
          className="bg-[#12122A] mt-2 p-2 rounded border border-gray-600 w-full focus:outline-none focus:border-red-500"
          placeholder={`Type "${project.name}" to confirm`}
          value={deleteConfirmationText}
          onChange={(e) => setDeleteConfirmationText(e.target.value)}
        />
        <div className="mt-4 flex gap-2">
          <button
            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded disabled:opacity-50"
            disabled={
              isLoading || deleteConfirmationText.trim() !== project.name
            }
            onClick={() => {
              setAction("delete");
              setModalIsOpen(true);
            }}
          >
            Confirm Delete
          </button>
          <button
            className="bg-gray-600 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded"
            onClick={() => {
              setShowDeleteConfirmation(false);
              setDeleteConfirmationText("");
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    )}
  </div>
  )
}

export default ManageProjectSubscription;
