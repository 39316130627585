import authentication from "imgs/icons/authentication.svg"
import blockExplorerLogo from "imgs/icons/block_explorer.svg"
import bridgeLogo from "imgs/icons/bridge.svg"

import AdditionalService from "components/deployments/services/AdditionalService"


export default function EthereumServices({selectedServices, handleServiceToggle}){

    return (
        <div>
            <h5 className="text-white text-normal font-thin mb-2">Actvate additional services</h5>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <AdditionalService
                    logo={blockExplorerLogo}
                    name="Custom Block Explorer"
                    className="bg-[#0F0F2D]"
                    tooltip="Block Explorer service is used to track all transactions on your appchain, customized with your brand and logo"
                    checked={selectedServices.blockExplorer}
                    onChange={() => handleServiceToggle('blockExplorer')}
                />
                <AdditionalService
                    logo={bridgeLogo}
                    name="Token Bridge"
                    className="bg-[#0F0F2D]"
                    tooltip="Natively Bridge tokens from the Ethereum blockchain"
                    checked={selectedServices.tokenBridge}
                    onChange={() => handleServiceToggle('tokenBridge')}
                />
                {/* <AdditionalService
                    logo={authentication}
                    name="Authentication service"
                    className="bg-[#0F0F2D]"
                    tooltip="Require an API Key in order to interact with the RPC URL"
                    checked={selectedServices.authenticationService}
                    onChange={() => handleServiceToggle('authenticationService')}
                /> */}
            </div>
            <p className="mt-4 text-sm text-gray-400">
            🚀 More services coming soon...
        </p>
    </div>
    )
}