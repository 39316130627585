import ArbitrumLogo from "../imgs/icons/ArbitrumNitro.png";
import AnvilLogo from "../imgs/ethereum-eth-logo.svg";
import AztecLogo from "../imgs/icons/Aztec.png";
import MadaraLogo from "../imgs/icons/madara.png";
import OptimismLogo from "../imgs/icons/Optimism.png";
import ScrollLogo from "../imgs/icons/Scroll.jpg";
import StacksLogo from "../imgs/icons/Stacks.png";
import ZKSyncLogo from "../imgs/icons/ZKSync.png";


export default function getAppchainLogo(appchainType){
    switch (appchainType) {
        case "arbitrum":
            return ArbitrumLogo;
        case "authenticated_madara":
            return MadaraLogo;
        case "anvil":
            return AnvilLogo;
        case "aztec":
            return AztecLogo;
        case "madara":
            return MadaraLogo;
        case "optimism":
            return OptimismLogo;
        case "scroll":
            return ScrollLogo;
        case "stacks":
            return StacksLogo;
        case "zksync":
            return ZKSyncLogo;
        default:
            return AnvilLogo;
    }
}