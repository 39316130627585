import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Select, SelectItem} from '@tremor/react';
import {motion} from "framer-motion";
import {RiArrowLeftSLine, RiArrowRightSLine} from "react-icons/ri";
import {IoFilterOutline} from "react-icons/io5";
import {FaSearch, FaSync} from 'react-icons/fa';
import {getAppchainService} from "../../factories/deployments/appchainServiceFactory";
import {jobService} from "../../services/jobs/job";

const DisplayServiceLogs = () => {
  const { serviceName, projectId, serviceId } = useParams();
  const [loading, setIsLoading] = useState(true);
  const [logData, setLogData] = useState(null);
  const [isFilterBarOpen, setIsFilterBarOpen] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const fetchServiceLogs = async () => {
    let result = await getAppchainService(serviceName).getLogs(projectId, serviceId, searchText);
    let tries = 0;
    let jobResult = await jobService.getTaskResult(result.task_id);
    while (tries < 4 && jobResult.status === "PENDING"){
      tries++;
      await new Promise(resolve => setTimeout(resolve, 1000));
      jobResult = await jobService.getTaskResult(result.task_id)
      console.log(jobResult);
    }
    return jobResult.result ? jobResult.result : {log_content: "Error fetching logs ... Ask Support"};
  };

  const initialFetchServiceLogs = async () => {
    setIsLoading(true);
    const data = await fetchServiceLogs();
    setIsLoading(false);
    setLogData(data);
  };

  useEffect(() => {
    initialFetchServiceLogs();
  }, [serviceName, projectId, serviceId]);

  const handleReload = async () => {
    setIsReloading(true);
    const data = await fetchServiceLogs();
    setLogData(data);
    setIsReloading(false);
  };

  const parseLogData = (data) => {
    return data.split("\n").filter((line) => line.trim() !== "");
  }

  const renderSkeleton = () => (
    <div className="animate-pulse">
      <div className="bg-gray-700 h-8 w-1/2 mb-4 rounded"></div>
      <div className="space-y-4">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="bg-gray-700 h-6 w-full rounded"></div>
        ))}
      </div>
      <div className="bg-gray-700 h-8 w-1/3 mt-8 mb-4 rounded"></div>
      <div className="space-y-4">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="bg-gray-700 h-6 w-full rounded"></div>
        ))}
      </div>
    </div>
  );

  if (loading) return (
    <div className="bg-[#020215] min-h-screen py-20 px-4">
      {renderSkeleton()}
    </div>
  );

  const logEntries  = parseLogData(logData.log_content);

  const filteredLogEntries = logEntries.filter(entry =>
    entry.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="relative px-2">
      <div className="bg-[#020215] pt-6 mt-4 flex flex-row">
        {/* left div */}
        <motion.div
          initial={{ x: -300 }}
          animate={{ x: isFilterBarOpen ? 0 : -300 }}
          transition={{ duration: 0.3 }}
          className="min-w-64 border-r border-gray-700 min-h-screen px-6 z-10 bg-[#020215] fixed"
        >
          <div className="flex justify-between items-center py-4">
            <h2 className="text-sm text-white font-semibold">Filters</h2>
            <button onClick={() => setIsFilterBarOpen(!isFilterBarOpen)}>
              {isFilterBarOpen ? <RiArrowLeftSLine size={24} /> : <RiArrowRightSLine size={24} />}
            </button>
          </div>
          <Select defaultValue="1">
            <SelectItem value="1">30 minutes</SelectItem>
            <SelectItem value="2">Past Day</SelectItem>
            <SelectItem value="3">Past Week</SelectItem>
          </Select>
        </motion.div>

        {/* right div */}
        <motion.div
          initial={{ marginLeft: 0 }}
          animate={{ marginLeft: isFilterBarOpen ? 256 : 0 }}
          transition={{ duration: 0.3 }}
          className="flex-grow"
        >
          {/* top bar */}
          <div className="flex flex-row items-center ml-5">
            <div onClick={() => setIsFilterBarOpen(!isFilterBarOpen)} className="p-3 bg-[#09091B] text-white rounded border border-gray-700 hover:opacity-70 cursor-pointer">
              <IoFilterOutline size={19} />
            </div>
            <div className="bg-[#09091B] rounded border border-gray-700 m-4 w-11/12">
              <div className="flex flex-row items-center">
                <FaSearch className="ml-4 opacity-40" size={18} />
                <input 
                  type="text" 
                  placeholder="Search logs..." 
                  className="bg-transparent border-transparent text-white focus:outline-0 focus:ring-0 focus:border-transparent w-full"
                  value={searchText}  // Línea agregada
                  onChange={(e) => setSearchText(e.target.value)}  // Línea agregada
                />
              </div>
            </div>
          </div>

          {/* main content */}
          {/* logs */}
          <div className="px-4 pb-10">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl tracking-tight text-white px-2">Appchain Log</h1>
              <button onClick={handleReload} className="text-white hover:opacity-90 flex items-center">
                {isReloading ? (
                  <FaSync className="animate-spin" size={14} />
                ) : (
                  <FaSync size={14} />
                )}
                <span className="ml-2">Refresh Log</span>
              </button>
            </div>

            {isReloading ? (
              <div className="animate-pulse mt-4">
                <div className="bg-gray-700 h-4 w-1/6 mb-4 rounded"></div>
                <div className="space-y-4">
                  {[...Array(5)].map((_, index) => (
                    <div key={index} className="bg-gray-700 h-6 w-full rounded"></div>
                  ))}
                </div>
                <div className="bg-gray-700 h-8 w-1/3 mt-8 mb-4 rounded"></div>
              </div>
            ) : (
              <div className="-space-y-2 text-sm text-white font-mono opacity-80">
                {filteredLogEntries.map((entry, index) => (  // Línea modificada
                  <div key={index} className="p-2">
                    {entry}
                  </div>
                ))}
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default DisplayServiceLogs;
