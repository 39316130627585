import React, { useState, useEffect } from "react";
import { ethers } from "ethers";

const AddChainMetaMask = ({ appChain, address, setAddress, balance, setBalance, chainData, fetchBalance, }) => {

  const [rpcConnected, setRpcConnected] = useState(false);
  const [error, setError] = useState(null);

  const checkIfConnected = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
  
      // Get the current network chain ID
      const network = await provider.getNetwork();
      const connectedChainId = `0x${network.chainId.toString(16)}`;
  
      // Check if the wallet is connected to the appChain
      if (connectedChainId === chainData.chainId) {
        console.log("Connected to the appChain!");
  
        // Get the user's accounts
        const accounts = await provider.listAccounts();
        if (accounts.length > 0) {
          setAddress(accounts[0]);
  
          // Fetch balance using the appChain RPC URL
          const rpcProvider = new ethers.providers.JsonRpcProvider(chainData.rpcUrls[0]);
          const balance = await rpcProvider.getBalance(accounts[0]);
          setBalance(ethers.utils.formatEther(balance)); // Format balance to Ether
  
          setRpcConnected(true); // Mark RPC as connected
        }
      } else {
        console.warn(
          `Connected to chain ${connectedChainId}, but expected ${chainData.chainId}.`
        );
        setRpcConnected(false);
      }
    } catch (err) {
      console.error("Error checking connection:", err);
      setRpcConnected(false);
    }
  };
  

  // Connect Wallet
  const connectWallet = async () => {
    try {
      // Use Web3Provider for wallet interaction
      const provider = new ethers.providers.Web3Provider(window.ethereum);
  
      // Request accounts from the wallet
      const accounts = await provider.send("eth_requestAccounts", []);
      setAddress(accounts[0]);
  
      // Fetch balance using the appChain's RPC URL
      fetchBalance(accounts[0]);
    } catch (err) {
      console.error("Error connecting wallet:", err);
    }
  };

  // Switch Chain
  const switchChain = async () => {
    console.log("here")
    await addChain();
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainData.chainId }],
      });
    } catch (err) {
      console.log(err)
      if (err.code === 4902) {
        console.log("here?")
        await addChain();
      } else {
        setError(err);
      }
    }
  };


  async function requestPermissions() {
    try {
      const permissions = await window.ethereum.request({
        method: "wallet_requestPermissions",
        params: [{ eth_accounts: {} }],
      });
      console.log("Permissions granted:", permissions);
    } catch (error) {
      if (error.code === 4001) {
        console.log("User rejected the permissions request.");
      } else {
        console.error("Error requesting permissions:", error);
      }
    }
  }

  // Add Chain
  const addChain = async () => {
    try {
      await requestPermissions();
      console.log("test")
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [chainData],
      });
      console.log("Chain added successfully.");
      checkIfConnected()
    } catch (err) {
      console.error("Error adding chain:", err);
    }
  };

  const handleAccountChange = async (accounts) => {
    if (accounts.length > 0) {
      setAddress(accounts[0]);
      fetchBalance(accounts[0]); // Fetch balance for the new account
    } else {
      // User disconnected their wallet
      console.log("No accounts connected");
      setAddress(null);
      setBalance(null);
    }
  };

  useEffect(() => {
    if(appChain.is_deployed){
      checkIfConnected();
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", handleAccountChange);
        return () => {
          window.ethereum.removeListener("accountsChanged", handleAccountChange);
        };
      }
    }
  }, [appChain]);

  if (!appChain.is_deployed){
    return (
      <div className="p-6 bg-[#0A0A22] border border-white/10 rounded-lg h-auto">
      <h2 className="text-2xl font-light text-white mb-4">Wallet Configuration</h2>
        <p>Waiting for Appchain deployment...</p>
      </div>
    )
  }

  return (
    <div className="p-6 bg-[#0A0A22] border border-white/10 rounded-lg h-auto">
      <h2 className="text-2xl font-light text-white mb-4">Wallet Configuration</h2>
      {address ? (
        rpcConnected ? (
          <div className="bg-gray-800 p-4 rounded-lg mb-4 text-white">
            <p className="text-sm mb-2">Connected Address:</p>
            <div className="text-lg font-mono truncate">{address}</div>
            <p className="text-sm mt-2">Balance:</p>
            <div className="text-xl font-semibold">{balance} ETH</div>
          </div>
        ) : (
          <div className="space-y-4">
            <p className="text-yellow-500 text-sm mb-2">
              Connected to wallet but not to the appChain. Please switch chains.
            </p>
            <button
              onClick={switchChain}
              className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors duration-200 w-full"
            >
              Switch Chain
            </button>
          </div>
        )
      ) : (
        <div className="space-y-4">
          <button
            onClick={connectWallet}
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors duration-200 w-full"
          >
            Connect Wallet
          </button>
          <button
            onClick={switchChain}
            className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors duration-200 w-full"
          >
            Switch Chain
          </button>
        </div>
      )}
      {error && (
        <span className="text-red-500 text-sm mt-2 block">{error.message}</span>
      )}
    </div>
  );
};

export default AddChainMetaMask;