import {useEffect, useState, useMemo, useCallback} from "react";

import projectService from "../../services/deployments/project";

import {useNavigate} from "react-router-dom";

import getAppchainDefaultMetaData from "data/appchainMetaData";

import getSummaryItems from "utils/summaryItems";
import appchainFrameworkService from "services/refs/appchainFramework";
import settlementLayerData from 'data/settlmentLayerData';


const defaultServiceConfig = {
    "blockExplorer": false,
    "torii": false,
    "tokenBridge": false,
    "authenticationService": false,
    "quasmWallet": false,
}

const DEFAULT_FRAMEWORK = 'madara';
const DEFAULT_ENV = 'staging';
const DEFAULT_SETTLEMENT_LAYER = "SS";

export default function useLaunchAppchain() {

    const defaultError = {
        "projectName": "",
        "permissionDenied": false,
    }

    const [selectedEnvironment, setSelectedEnvironment] = useState(DEFAULT_ENV)
    const [selectedFramework, setSelectedFramework] = useState(DEFAULT_FRAMEWORK)
    const [selectedSettlementLayer, setSelectedSettlementLayer ] = useState(DEFAULT_SETTLEMENT_LAYER)
    const [ settlementLayers, setSettlementLayers ] = useState(settlementLayerData)
    const [appchainFrameworkData, setAppchainFrameworkData ] = useState([])
    const [projectName, setProjectName] = useState("")
    const [projectVisibilities, setProjectVisibilities] = useState([]);
    const [projectVisibility, setProjectVisibility] = useState(null);
    const [metaData, setMetaData] = useState(getAppchainDefaultMetaData(DEFAULT_FRAMEWORK))
    const [selectedServices, setSelectedServices] = useState(defaultServiceConfig)
    const [error, setError] = useState(defaultError)
    const [selectedVersion, setSelectedVersion] = useState('')
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchVisibilityStatus = async () => {
            const visibilities = (await projectService.getVisibilityStatus()).status;
            setProjectVisibilities(visibilities);
            setProjectVisibility(visibilities[0].value)
        }

        const fetchAppchainFrameworks = async () =>{
            const frameworks = await appchainFrameworkService.getAppchainFrameworks()
            setAppchainFrameworkData(frameworks)
        }

        fetchAppchainFrameworks();
        fetchVisibilityStatus();
        updateSettlementLayers(selectedEnvironment);
    }, []);


    function filterSettlementLayerByEnvironmentType(type) {
        return settlementLayerData.filter(layer => layer.type === type);
    }

    const updateSettlementLayers = (env) => {
        let newLayers = filterSettlementLayerByEnvironmentType(env)
        setSettlementLayers(newLayers)
    }

    const onEnvironmentChange = (env) => {
        setSelectedEnvironment(env)
        updateSettlementLayers(env)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(defaultError)
        if (!projectName) {
            setError({
                ...error,
                projectName: "You must have a Project Name"
            })
            return
        }

        try {
            setIsSubmitting(true);
            metaData.network = selectedEnvironment;
            const data = await projectService.createAppchain(selectedFramework, {
                project: {
                    name: projectName, visibility: projectVisibility
                },
                version: selectedVersion, 
                metadata: metaData,
                services: selectedServices
            })
            navigate("/projects/" + data.project);
        } catch (err) {
            if (err.response.status === 403) {
                setError({
                    ...error,
                    permissionDenied: true
                });
            } else if (err.response.status === 400){
                setError({
                    ...error, metadata: err.response.data.detail
                })
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const baseItems = useMemo(() => {
        let items = getSummaryItems(metaData, selectedFramework);
        return items;
    }, [selectedFramework, metaData]);
    
    const [appendedItems, setAppendedItems] = useState([]);

    const appendItem = useCallback((newItem) => {
        setAppendedItems(prevItems => [...prevItems, newItem]);
    }, []);

    const summaryItems = useMemo(() => [...baseItems, ...appendedItems], [baseItems, appendedItems]);

    const changeAppchainFramework = (appchainFramework) => {
        setSelectedServices(defaultServiceConfig)
        setSelectedFramework(appchainFramework);
        const chosenMetadata = getAppchainDefaultMetaData(appchainFramework)
        setMetaData(chosenMetadata);
    }

    const changeSettlementLayer = (settlementLayer) =>{
        setSelectedSettlementLayer(settlementLayer)
    }


    return {
        selectedEnvironment,
        setSelectedEnvironment,
        selectedFramework,
        setSelectedFramework,
        appchainFrameworkData,
        selectedSettlementLayer,
        setSelectedSettlementLayer,
        projectName,
        setProjectName,
        projectVisibilities,
        projectVisibility,
        setProjectVisibility,
        metaData,
        setMetaData,
        error,
        setError,
        selectedVersion,
        setSelectedVersion,
        summaryItems,
        appendItem,
        handleSubmit,
        selectedServices,
        setSelectedServices,
        isSubmitting,
        changeAppchainFramework,
        changeSettlementLayer,
        settlementLayers,
        onEnvironmentChange
    }
}