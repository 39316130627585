import {BaseService} from "../core/base";


class WaitlistService extends BaseService{
    
    async checkWaitlist(){
        try{
            const resp = await this.apiClient.get(
                "/billing/waitlist"
            )
            return resp.data
        } catch{
            return false
        }

    }

    async joinWaitlist(){
        const resp = await this.apiClient.post(
            "/billing/waitlist"
        )
        return resp.data;
    }

    async submitAnswers(id, data){
        const resp = await this.apiClient.patch(
            `/billing/waitlist/${id}`,
            data
        )
        return resp.data;
    }
}


const waitlistService = new WaitlistService();
export default waitlistService;
