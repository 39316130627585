import React from 'react';
import { FaSync, FaSearch } from 'react-icons/fa';

import useLogData from '../../hooks/deployments/useLogData';


const LogSection = ({appChain, logEntries})=>{
    

    if (appChain && !appChain.is_deployed){
        return (
            <div className="mt-4 flex flex-col space-y-2 bg-[#0A0A22] rounded-lg p-4 border border-white/10 overflow-auto h-[48rem] relative group">
                <p className="text-sm font-mono font-light text-gray-500">Currently deploying your appchain.</p>
            </div>
        )
    }


    return(
        <div className="mt-4 flex flex-col space-y-2 bg-[#0A0A22] rounded-lg p-4 border border-white/10 overflow-auto h-[48rem] relative group">
            {logEntries.length > 0 ? (
                logEntries.map((log, index) => {
                    let logType = 'text-gray-300';
                    if (log.includes('ERROR')) {
                    logType = 'text-red-500';
                    } else if (log.includes('DEBUG')) {
                    logType = 'text-blue-400';
                    } else if (log.includes('TRACE')) {
                    logType = 'text-green-400';
                    }
                    return (
                        <p key={index} className={`text-sm font-mono font-light ${logType}`}>
                            {log}
                        </p>
                    );
                })
            ) : (
                <p className="text-sm font-mono font-light text-gray-500">No logs available.</p>
            )}
    </div>
    )
}


const LogsDisplay = ({ appChain }) => {

    const { logEntries, isReloading, handleReload, setSearchQuery, searchQuery, autoRefresh, setAutoRefresh } = useLogData(appChain);

    return (
        <div className="col-span-2 flex flex-col space-y-6">
            {/* Header */}
            <div className="rounded-lg">
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-3xl font-thin text-gray-200">Service Logs</h1>
                <div className="flex items-center">
                    {/* Toggle Switch */}
                    <label className="relative inline-flex items-center cursor-pointer mr-4">
                        <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={autoRefresh}
                            onChange={() => setAutoRefresh(!autoRefresh)}
                            disabled={appChain ? !appChain.is_deployed || !appChain.is_started : true}
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-sm font-medium text-gray-200">
                            Auto-Refresh {autoRefresh ? 'ON' : 'OFF'}
                        </span>
                    </label>

                    {/* Reload Button */}
                    <button
                        onClick={handleReload}
                        className="flex items-center font-normal text-sm bg-white hover:white/90 h-10 text-black rounded-md p-2 px-4"
                        disabled={appChain ? !appChain.is_deployed || !appChain.is_started : true}
                    >
                        <FaSync
                            size={12}
                            className={`mr-2 ${isReloading ? 'animate-spin' : ''}`}
                        />
                        {isReloading ? 'Reloading...' : 'Reload'}
                    </button>
                </div>
            </div>
            </div>

            {/* Search Bar */}
            <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaSearch className="h-4 w-4 text-gray-400" />
                </div>
                <input
                    type="text"
                    placeholder="Search logs..."
                    className="w-full px-4 py-2 pl-8 bg-[#13132E] border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-300 placeholder-gray-400"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            {/* Logs Section */}
            <LogSection appChain={appChain} logEntries={logEntries}/>
        </div>
    );
};




export default LogsDisplay;