import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { IoWalletOutline, IoAnalyticsOutline, IoEyeOutline } from "react-icons/io5";
import { FiPlusCircle } from "react-icons/fi";
import PrimaryButton from "../../components/PrimaryButton";
import appchainService from "../../services/deployments/appchain";
import ProjectStripeSubscription from "../../components/billing/ProjectStripeSubscription";
import { getAppchainService } from "../../factories/deployments/appchainServiceFactory";
import BackButton from "../../components/ui/BackButton";
import { toast, ToastContainer } from "react-toastify";

const iconMapping = {
  "Block Explorer": IoEyeOutline,
  Indexer: IoAnalyticsOutline,
  Wallet: IoWalletOutline,
};

const AvailableServicesPage = () => {
  const { appchainId } = useParams();
  const [services, setServices] = useState([]);
  const [project, setProject] = useState(null);
  const [loadingService, setLoadingService] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toriiWorldAddress, setToriiWorldAddress] = useState("");

  useEffect(() => {
    const fetchServices = async () => {
      if (!appchainId) {
        setError("Appchain ID is missing from the URL.");
        setIsLoading(false);
        return;
      }

      try {
        const data = await appchainService.getAvailableServices(appchainId);
        setServices(
          data.services.map((service) => ({
            ...service,
            icon: iconMapping[service.type] || FiPlusCircle,
          }))
        );
        setProject(data.project);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setError("Failed to fetch available services.");
        setIsLoading(false);
      }
    };

    fetchServices();
  }, [appchainId, loadingService]);

  const deployService = async (service) => {
    if (!project?.is_professional_plan) {
      return;
    }
    setLoadingService(service.identifier);

    try {
      const apiService = getAppchainService(service.identifier);
      if (service.identifier === "torii") {
        await apiService.create(project.id, {
          katana_app: appchainId,
          project_id: project.id,
          world_address: toriiWorldAddress,
        });
      } else {
        await apiService.create(project.id, { appchain: appchainId });
      }
    } catch (e) {
      console.log(e);
      toast.error(e?.response?.data?.detail || "An error occurred.");
    } finally {
      setLoadingService(null);
    }
  };

  console.log(services)

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-[#020215] to-[#12121C] text-white">
        <div className="flex items-center gap-4 animate-pulse">
          <div className="h-10 w-10 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
          <span className="text-lg font-semibold">Loading services...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center text-red-500">
        <span>{error}</span>
      </div>
    );
  }

  if (services.length === 0) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center text-gray-300">
        <h2 className="text-2xl font-semibold mb-4">No services available</h2>
        <p className="text-lg text-gray-400">
          There are currently no services available for this Stack. We're working
          hard to bring you services soon!
        </p>
      </div>
    );
  }

  return (
    <div className="min-h-screen text-white p-8 md:p-16 bg-gradient-to-b from-[#020215] to-[#12121C]">
      <ToastContainer />

      <div className="flex items-center my-6 space-x-4">
        <BackButton />
      </div>

      {/* If user is not on the professional plan */}
      {!project?.is_professional_plan && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="bg-[#0B0B20] text-white p-6 rounded-lg mb-8 text-center shadow-lg border border-gray-700"
        >
          <h2 className="text-xl font-bold mb-2">Unlock Premium Services</h2>
          <p className="text-md text-gray-300">
            Upgrade to a professional plan to gain access to premium services
            and features.
          </p>

          <ProjectStripeSubscription
            projectId={project.id}
            text={"Upgrade to Professional"}
            isUpgrade={true}
          />
        </motion.div>
      )}

      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-center mb-12"
      >
        <h1 className="text-4xl font-extrabold tracking-wide mb-4">
          Enhance Your Appchain
        </h1>
        <p className="text-gray-400 text-lg">
          Choose from powerful services for {project?.name || "your Appchain"}.
        </p>
      </motion.div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {services.map((service) => {
          // Determine if the button should be disabled
          const isDisabled =
            loadingService === service.identifier ||
            !project?.is_professional_plan ||
            service.is_active ||
            (service.identifier === "torii" && !toriiWorldAddress);

          return (
            <motion.div
              key={service.identifier}
              whileHover={{ scale: 1.05 }}
              className="bg-[#0B0B20] border border-gray-700 rounded-xl p-8 shadow-lg hover:shadow-xl transition-transform"
            >
              <div className="flex items-center space-x-4 mb-6">
                <div className="bg-[#2C2C40] p-4 rounded-full flex justify-center items-center shadow-md">
                  <service.icon
                    className="text-5xl text-accent"
                    aria-label={`${service.name} Icon`}
                  />
                </div>
                <div>
                  <h2 className="text-2xl font-semibold">{service.name}</h2>
                  <p className="mt-2 text-sm text-gray-400">
                    Type: {service.type}
                  </p>
                </div>
              </div>

              {/* Render the Torii input field only if the service name is "Torii" */}
              {service.identifier === "torii" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-400">
                    Torii World Address
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your Torii world address"
                    value={toriiWorldAddress}
                    onChange={(e) => setToriiWorldAddress(e.target.value)}
                    className="w-full px-3 py-2 rounded-lg bg-[#1a1a2c] border border-gray-700 focus:outline-none focus:ring-2 focus:ring-accent"
                  />
                </div>
              )}

              <PrimaryButton
                onClick={() => deployService(service)}
                disabled={isDisabled}
                className={`transition-all w-full ${
                  loadingService === service.identifier
                    ? "opacity-70 cursor-not-allowed bg-gray-700"
                    : ""
                }`}
              >
                <motion.div
                  whileHover={{ x: 3 }}
                  className="flex items-center justify-center gap-3"
                >
                  {loadingService === service.identifier ? (
                    <div className="flex gap-2 items-center animate-pulse">
                      <div className="loader-spinner border-t-2 border-white rounded-full w-5 h-5"></div>
                      <span>Adding...</span>
                    </div>
                  ) : (
                    <>
                      <span>
                        {project?.is_professional_plan
                          ? service.is_active
                            ? "Service Already Active"
                            : "Add Service"
                          : "Upgrade Required"}
                      </span>
                    </>
                  )}
                </motion.div>
              </PrimaryButton>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default AvailableServicesPage;