import React, {useState, useEffect} from "react";
import { FiLoader, FiUploadCloud, FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import projectService from "services/deployments/project";


const LogoUpload = ({ logoUrl, uploadLogo, handleDeleteLogo }) => (
    <div className="mb-6 flex flex-col items-center">
      <div className="relative w-40 h-40 border-2 border-dashed border-gray-600 rounded-full flex items-center justify-center hover:border-blue-500 transition-all">
        {logoUrl ? (
          <img
            src={logoUrl}
            alt="Project Logo"
            className="rounded-full object-cover w-full h-full"
          />
        ) : (
          <label
            htmlFor="logo-upload"
            className="absolute inset-0 flex flex-col items-center justify-center cursor-pointer text-gray-400 hover:text-white transition-all"
          >
            <FiUploadCloud className="text-4xl mb-2" />
            <span className="text-sm">Upload Logo</span>
            <input
              type="file"
              id="logo-upload"
              onChange={uploadLogo}
              className="hidden"
            />
          </label>
        )}
        {logoUrl && (
          <button
            className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-500 focus:outline-none"
            onClick={handleDeleteLogo}
            aria-label="Delete logo"
          >
            <FiTrash2 className="text-xl" />
          </button>
        )}
      </div>
    </div>
  );

export default function GeneralSettings({project, fetchProject}) {
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [socialLinks, setSocialLinks] = useState({
        github: '',
        x: '',
        telegram:'',
        discord: ''
    });
    const [projectLink, setProjectLink] = useState("");
    const [loading, setLoading] = useState(false);
    const [projectVisibility, setProjectVisibility] = useState(null);

    useEffect(() => {
        if(project){
            setProjectVisibility(project.visibility)
            setLogoUrl(project.logo_url);
            setProjectLink(project.metadata?.project_link ?? "");
            setSocialLinks({
                github: project.github_link ?? '',
                x: project.x_link ?? '',
                telegram: project.telegram_link ?? '',
                discord: project.discord_link ?? ''
            });
        }
    }, [project]);

    console.log(projectVisibility)

    const uploadLogo = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                setLoading(true);
                setLogo(file);
                await projectService.addLogo(project.id, file);
                fetchProject();
                toast.success("Logo uploaded successfully!");
            } catch (error) {
                toast.error("Failed to upload logo, please try again.");
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDeleteLogo = () => {
        setLogo(null);
        setLogoUrl(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSocialLinks(prevLinks => ({
            ...prevLinks,
            [name]: value
        }));
    };

    const saveProject = async () => {
        setLoading(true);
        try {
            const data = {
                metadata: {
                    socials: socialLinks,
                    project_link: projectLink
                },
                visibility: projectVisibility
            };
            await projectService.update(project.id, data);
            fetchProject();
            toast.success('Changes saved successfully!');
        } catch (error) {
            toast.error("Something went wrong. Please try later.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="space-y-6">
            <div className="bg-[#0B0B20] p-6 rounded-lg">
                <LogoUpload logoUrl={logoUrl} uploadLogo={uploadLogo} handleDeleteLogo={handleDeleteLogo}/>
            </div>
            <div className="bg-[#0B0B20] p-6 rounded-lg">
            <label className="block text-sm font-medium">Visibility</label>
            <select 
                className="mt-2 w-full bg-[#020215] border border-gray-600 p-2 rounded-md text-white"
                value={projectVisibility}
                onChange={(e)=>setProjectVisibility(e.target.value)}
            >
                <option value="2">Public</option>
                <option value="1">Private</option>
            </select>
            </div>
            <div className="space-y-4">
            <div className="bg-[#0B0B20] p-6 rounded-lg">
                <label className="block text-sm font-medium">Project Link</label>
                <input 
                    type="text" 
                    name="project_link"
                    value={projectLink}
                    onChange={(e) => setProjectLink(e.target.value)}
                    placeholder="Project App Link"
                    className="w-full px-4 py-2 bg-[#12121C] border border-white/20 rounded-md text-white placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 transition duration-200"
                />
                </div>
            {["github", "x", "telegram", "discord"].map(
                (field) => (
                <div key={field} className="bg-[#0B0B20] p-6 rounded-lg">
                    <label className="block text-sm font-medium">{field.charAt(0).toUpperCase() + field.slice(1)} Link</label>
                    <input 
                        type="text" 
                        name={field}
                        value={socialLinks[field]}
                        onChange={handleInputChange}
                        placeholder={`${field.charAt(0).toUpperCase() + field.slice(1)} Link`}
                        className="w-full px-4 py-2 bg-[#12121C] border border-white/20 rounded-md text-white placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 transition duration-200"
                    />
                </div>
                )
            )}
            </div>
            {/* Save Button */}
            <div className="flex justify-end mt-6">

            {loading ? <FiLoader className="animate-spin mr-2"/> : 
                <button 
                    onClick={saveProject}
                    className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                    Save
                </button>
            }
                
            </div>
        </div>
    )
}
