import { NavLink } from 'react-router-dom';
import { FaDiscord } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="bg-tremor-dark dark:bg-dark-tremor-dark text-tremor-content dark:text-dark-tremor-content">
      <div className="container mx-auto px-4 py-6">
        <div className="grid grid-cols-2 gap-4">
          <p className="text-center text-sm">
            Quasm LLC &copy; 2025 All rights reserved
          </p>
          <div className="flex flex-wrap justify-center items-center space-x-4">
            <NavLink
              to="/privacy"
              className="text-sm text-center hover:underline"
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="/terms"
              className="text-sm text-center hover:underline"
            >
              Terms and Conditions
            </NavLink>
            <a
              href="https://discord.gg/FErBNC82"
              className="text-sm text-center hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaDiscord />
            </a>
            <a
              href="https://x.com/QuasmIO"
              className="text-sm text-center hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSquareXTwitter />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
