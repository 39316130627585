import BaseLogo from "imgs/icons/Base.png";
import StarknetLogo from "imgs/icons/SN-Symbol-Gradient.png";
import Ethereum from "imgs/ethereum-eth-logo.svg";

const settlementLayerData = [
    {
        text: "Ethereum",
        logo: Ethereum,
        frameworkKey: "ETH",
        tooltipText: "Ethereum Settlement Layer",
        description: "A robust and decentralized settlement layer leveraging Ethereum's mainnet, optimized by Quasm to ensure reliability and scalability for your production-grade applications.",
        idealFor: "Deploying secure, high-value applications with guaranteed decentralization and immutability.",
        features: [
            "Battle-tested security of Ethereum mainnet",
            "Supports the most widely-used Layer 1 protocol",
            "Global adoption with deep liquidity and active developers",
        ],
        type: "production",
    },
    {
        text: "Staging Settlement",
        logo: Ethereum,
        frameworkKey: "SS",
        tooltipText: "Staging Settlement Layer",
        description: "A cost-effective and sandboxed environment designed for testing deployments before production, backed by Ethereum's mainnet compatibility.",
        idealFor: "Simulating real-world scenarios, testing upgrades, and debugging applications in a controlled environment.",
        features: [
            "Environment optimized for pre-production testing",
            "Reduced costs compared to mainnet deployments",
            "Supports advanced debugging and monitoring tools",
        ],
        type: "staging",
    },
    {
        text: "Starknet",
        logo: StarknetLogo,
        frameworkKey: "STRK",
        tooltipText: "Starknet Layer 2 Settlement",
        description: "A next-generation Layer 2 settlement layer built on StarkNet, offering unparalleled scalability and reduced transaction costs while retaining Ethereum's security guarantees.",
        idealFor: "Developers seeking high throughput and low-latency solutions for dApps while benefiting from Ethereum's security.",
        features: [
            "Powered by zk-rollups for extreme scalability",
            "Ideal for micro-transactions and gaming use cases",
            "Significantly lower gas costs compared to Ethereum",
        ],
        type: "production",
    },
    {
        text: "Base",
        logo: BaseLogo,
        frameworkKey: "BASE",
        tooltipText: "Base Layer 2 Settlement",
        description: "An Ethereum Layer 2 solution designed by Base, enhanced by Quasm to deliver scalability, low fees, and high performance for your decentralized applications.",
        idealFor: "Scaling dApps with minimal fees and faster transaction speeds while maintaining Ethereum compatibility.",
        features: [
            "Optimized for DeFi applications with high transaction volumes",
            "Seamless integration with Coinbase ecosystem",
            "Near-instant finality for user-friendly dApps",
        ],
        type: "production",
    },
];

export default settlementLayerData;
