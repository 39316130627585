export class DeployableServiceMixin{

    async create(projectId, data){
        const resp = await this.apiClient.post(this.getUri(projectId), data);
        return resp.data;
    }

    async list(projectId){
        const response = await this.apiClient.get(this.getUri(projectId));
        return response.data;
    }

    async retrieve(projectId, deployableId){
        let finalUri = this.getUri(projectId) + deployableId + "/";
        const resp = await this.apiClient.get(finalUri);
        return resp.data;
    }

     async getLogs(projectId, deployableId, searchText) {
        let finalUri = [null, undefined, ""].includes(searchText)
          ? this.getUri(projectId) + deployableId + "/logs/"
          : this.getUri(projectId) + deployableId + "/logs/?grep_string=" + searchText;
        const resp = await this.apiClient.get(finalUri);
        return resp.data;
    }

    async restart(projectId, deployableId){
        let url = this.getUri(projectId) + deployableId + "/restart/";
        return this.apiClient.post(url)
    }

    async stop(projectId, deployableId){
        let url = this.getUri(projectId) + deployableId + "/stop/";
        return this.apiClient.post(url)
    }

    async start(projectId, deployableId){
        let url = this.getUri(projectId) + deployableId + "/start/";
        return this.apiClient.post(url)
    }

    async delete(projectId, deployableId){
        let url = this.getUri(projectId) + deployableId + "/";
        return this.apiClient.delete(url);
    }

    async deploy(projectId, deployableId, data){
        let url = this.getUri(projectId) + deployableId + "/deploy/";
        return this.apiClient.post(url, data);
    }
}