import React, { useState } from "react"
import { FiLoader } from 'react-icons/fi';
import { FaEthereum } from 'react-icons/fa';
import { getAppchainService } from "../../factories/deployments/appchainServiceFactory"
import { jobService } from "services/jobs/job";
import { toast } from "react-toastify";
import AnimatedContainer from "../AnimatedContainer";


const FaucetComponent = ({ appChain, address, fetchBalance }) => {
  const [isDispensing, setIsDispensing] = useState(false)
  const [dispenseData, setDispenseData] = useState({
    "amount": "50",
  })
  const [addressError, setAddressError] = useState("");

  if (!appChain) {
    return <div>Loading...</div>;
  }

  const handleDispense = async () => {
    let appChainService = getAppchainService(appChain.appchain_type)
    setIsDispensing(true)
    try {
      const res = await appChainService.dispenseFaucet(
        appChain.project,
        appChain.id,
        address,
        dispenseData.amount
      )
      await jobService.pollTaskResult(res.data.task_result)
      toast.success(`${dispenseData.amount} ether has been dispensed!`);
      fetchBalance(address)
    } catch (e) {
      console.log(e)
      toast.error(`Dispense has failed! Please try again or contact support.`);
    } finally {
      setIsDispensing(false)
    }
  }

  let appChainType = appChain.appchain_type.charAt(0).toUpperCase() + appChain.appchain_type.slice(1);
  let dispenseDisabled = isDispensing ||
    !appChain.is_deployed ||
    appChain.is_starting ||
    appChain.is_stopping ||
    appChain.is_error ||
    addressError;

  return (
    <AnimatedContainer delay={0.2}>
      <div className='p-4 bg-[#0A0A22] border border-white/10 rounded-lg h-80'>
        <h2 className='text-2xl text-start font-light text-white mb-2'>Gas Faucet</h2>
        <div className='space-y-4'>
          <div className='flex flex-col space-y-2'>
            <label htmlFor='ethAddress' className='block text-lg font-light text-white/80'>{appChainType} wallet Address</label>
            <input
              type='text'
              id='ethAddress'
              placeholder='0x...'
              className='w-full px-3 py-2 mt-1 bg-[#0F0F2D] border border-white/20 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white'
              defaultValue={address}
              readOnly
            />
          </div>
          <div className='flex flex-col space-y-2'>
            <label htmlFor='ethAmount' className='block text-lg font-light text-white/80'>Select Amount</label>
            <select
              id='ethAmount'
              className='w-full px-3 py-2 mt-1 bg-[#0F0F2D] border border-white/20 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white'
              defaultValue={dispenseData.amount}
              onChange={e => {
                setDispenseData({ ...dispenseData, "amount": e.target.value })
              }}
            >
              <option value='50'>50 ETH</option>
              <option value='100'>100 ETH</option>
              <option value='500'>500 ETH</option>
            </select>
          </div>
          <button
            className='h-10 text-base font-normal w-full px-4 py-2 bg-white text-black hover:bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 flex items-center justify-center cursor-pointer'
            onClick={handleDispense}
            disabled={dispenseDisabled}
          >
            {isDispensing ? (
              <FiLoader className="animate-spin h-5 w-5 mx-auto cursor-pointer" />
            ) : (
              <>
                <FaEthereum className="mr-2" />
                Dispense Ethereum
              </>
            )}
          </button>
        </div>
      </div>
    </AnimatedContainer>
  )
}

export default FaucetComponent