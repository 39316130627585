import toriiService from "../../services/deployments/torii";
import quasmCompassService from "../../services/deployments/quasm_compass";
import appchainService from "services/deployments/appchain";
import blockScoutService from "services/deployments/blockScout";


export const getAppchainService = (serviceName) => {
    if (serviceName === "torii") return toriiService;
    if (serviceName === "quasm-compass") return quasmCompassService;
    if (serviceName === "block-scout") return blockScoutService;
    return appchainService;
}
