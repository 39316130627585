import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GoogleAuthComponent from "../../components/auth/GoogleAuthComponent";
import GitHubAuthComponent from "../../components/auth/GithubAuthComponent";
import { useStateContext } from "contexts/ContextProvider";
import loginLogo from "../../imgs/loginLogo.svg"



export default function LogIn(){
    const navigate = useNavigate();
    const {
        googleLogin,
        onLoginFailure,
        user
    } = useStateContext()


    useEffect(()=>{
        if(user){
            navigate("/");
        }
    }, [user])


    return (
      <>
        <div className="bg-customBackground min-h-screen flex items-center justify-center">
        <div className="flex p-10 rounded-lg shadow-lg space-x-16 w-full max-w-7xl">
          <div className="text-white flex-1 flex flex-col justify-center">
            <h1 className="text-6xl font-bold mb-2 tracking-tight bg-gradient-to-t from-gray-500 to-white text-transparent bg-clip-text">Welcome to Quasm</h1>
            <p className="text-2xl text-gray-300">Enabling Fractal Scaling</p>
            <div className="flex flex-col space-y-4 mt-12">
                <GoogleAuthComponent login={googleLogin} handleError={onLoginFailure} />
                <GitHubAuthComponent />
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center">
            <img src={loginLogo} alt="Right container" className="max-w-full h-auto"/>
          </div>
        </div>
      </div>
      </>

    );
}