

export default function getSummaryItems(metaData, selectedFramework){
    let items = []
    switch (selectedFramework) {
        case "anvil":
            items = [
                `Built on Ethereuem`,
                `Block Time: ${metaData.block_time ? metaData.block_time + ' seconds': 'N/A' }`,
                `Gas Fee Disabled: ${metaData.is_gas_fee_disabled}`,
                `Auto Mining: ${metaData.is_auto_mining_enabled ?? false}`,
                "On-Demand Discord support"
            ];
            return items;

        case "arbitrum":
            items = [
                `Built on Arbitrum`,
                `Block Time: ${metaData.block_time ? metaData.block_time + ' seconds': 'N/A' }`,
                `Gas Fee Disabled: ${metaData.is_gas_fee_disabled}`,
                `Auto Mining: ${metaData.is_auto_mining_enabled ?? false}`,
                "On-Demand Discord support"
            ];
            return items;


        case "aztec":
            items = [
                `Built on Aztec`,
                `Block Time: ${metaData.block_time ? metaData.block_time + ' seconds': 'N/A' }`,
                `ChainID: ${metaData.chain_id ?? 'N/A'}`,
                `Auto Mining: ${metaData.is_auto_mining_enabled ?? false}`,
                "On-Demand Discord support"
            ];
            return items;

        case "optimism":
            items = [
                `Built on Optimism`,
                `Block Time: ${metaData.block_time ? metaData.block_time + ' seconds': 'N/A' }`,
                `Gas Fee Disabled: ${metaData.is_gas_fee_disabled}`,
                `ChainID: ${metaData.chain_id}`,
                `Auto Mining: ${metaData.is_auto_mining_enabled ?? false}`,
                "On-Demand Discord support"
            ];
            return items;

        case "madara":
            items = [
                `Built on Madara`,
                `Block Time: ${metaData.block_time} seconds`,
                `Gas Fee Disabled: ${metaData.is_gas_fee_disabled}`,
                `ChainID: ${metaData.chain_id}`,
                `Chain Name: ${metaData.chain_name}`,
                "On-Demand Discord support"
            ];
            break;

        case "scroll":
            items = [
                `Built on Scroll`,
                `Block Time: ${metaData.block_time ? metaData.block_time + ' seconds': 'N/A' }`,
                `Gas Fee Disabled: ${metaData.is_gas_fee_disabled}`,
                `ChainID: ${metaData.chain_id}`,
                `Auto Mining: ${metaData.is_auto_mining_enabled ?? false}`,
                "On-Demand Discord support"
            ];
            return items;

        case "stacks":
            items = [
                `Built on Stacks`,
                `Block Time: ${metaData.block_time} seconds`,
                `Gas Fee Disabled: ${metaData.is_gas_fee_disabled}`,
                `ChainID: ${metaData.chain_id}`,
                `Auto Mining: ${metaData.is_auto_mining_enabled ?? false}`,
                "On-Demand Discord support"
            ];
            return items;

        case "zksync":
            items = [
                `Built on ZKSync`,
                `Block Time: ${metaData.block_time ? metaData.block_time + ' seconds': 'N/A' }`,
                `ChainID: ${metaData.chain_id}`,
                `Auto Mining: ${metaData.is_auto_mining_enabled ?? false}`,
                "On-Demand Discord support"
            ];
            return items;
        default:
            break;
    }

    return items;
}