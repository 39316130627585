import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Badge } from '@tremor/react';
import { RiRecordCircleFill } from 'react-icons/ri';
import { FiMoreVertical } from 'react-icons/fi';

import getAppchainLogo from 'imgs/appchainLogo';
import { useNavigate } from 'react-router-dom';

const ProjectCard = ({ project }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative bg-[#0B0B20] min-w-[100%] md:min-w-[47%] lg:min-w-[30%] border border-input-border text-white rounded-lg shadow-md p-4 mr-5 mb-5 flex flex-col justify-between space-y-4 hover:border-white30">
      <div className="px-2 py-4">
        <div className="flex flex-row justify-between">
        <Link
            to={`/projects/${project.id}`}
            className="flex flex-col p-4 rounded-lg shadow-lg hover:shadow-xl transition duration-300 max-w-80"
          >
            {/* Header Section: Project Logo + Project Name */}
            <div className="flex items-center mb-4">
              {
                project.logo_url ?
                <img
                src={project.logo_url}
                alt="Project Logo"
                className="w-12 h-12 rounded-full mr-4 object-cover"
              /> 
                :null
              }
              <div className="text-xl text-white truncate">
                {project.name}
              </div>
            </div>

            {/* AppChains Section: Number of AppChains + Small Logo */}
            <div className="flex items-center text-white text-base mb-2">
              <img
                src={getAppchainLogo(project.appchain_type)}
                alt="AppChain Logo"
                className="w-6 h-6 mr-2 object-contain"
              />
              <span>{project.nb_of_appchains} AppChains</span>
            </div>

            {/* Deployment Date Section */}
            <div className="text-gray-400 text-sm">
              Deployed on {project.date_created_display}
            </div>
          </Link>
          <div className="relative flex flex-col justify-between">
            <button onClick={toggleMenu} className="text-white hover:text-gray-500 flex justify-end">
              <FiMoreVertical size={24} />
            </button>
            {isMenuOpen && (
              <div className="absolute right-0 mt-10 w-48 bg-shadow rounded-md z-20 bg-[#0B0B20] border border-white30">
                <ul>
                  <li>
                    <button className="w-full text-left px-5 py-4 text-sm hover:bg-red-100 rounded-md" onClick={() => {
                      navigate(`/projects/settings/${project.id}`)
                    }}>Settings</button>
                  </li>
                </ul>
              </div>
            )}
            {
              project.is_subscribed ?
                project.has_error ? 
                <Badge color='red-400' icon={RiRecordCircleFill}>Error</Badge>
                :
                  project.is_deploying ?
                  <Badge color='blue-400' icon={RiRecordCircleFill}>Deploying</Badge>
                  :
                  <Badge color='green-400' icon={RiRecordCircleFill}>Live</Badge>
              :
              <Badge color='yellow-400' icon={RiRecordCircleFill}>Waiting subscription</Badge>
            }
          </div>
        </div>
      </div>

    </div>
  );
};

export default ProjectCard;