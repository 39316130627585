import { CheckedInput, TitleInput, TextLeftInput } from "components/deployments/LaunchInputs"

import Timer from "../../../imgs/icons/Timer.svg"
import Plant from "../../../imgs/icons/Plant.svg"
import UserList from "../../../imgs/icons/UserList.svg"
import Link from "../../../imgs/icons/Link.svg"
import Info from "../../../imgs/icons/Info.svg"

import { TEN_PERCENT_BORDER } from "../../styles/styles"


const AnvilSpecs = ({metaData, setMetaData})=>{

    const handleInputChange = (key, value) => {
        setMetaData({
            ...metaData,
            [key]: value
        });
    };

    return (
        <div className="mt-14">
            <div className="flex justify-between items-center">
                <div className="flex justify-between items-end w-full">
                    <TitleInput 
                        Title={"Block Time"} 
                        logo={Timer} 
                        placeholder={"(milliseconds)"} 
                        style={{border: TEN_PERCENT_BORDER}}
                        name="block_time"
                        value={metaData.block_time}
                        onChange={handleInputChange}
                        className="w-1/4" 
                    ></TitleInput>
                    <TextLeftInput 
                        text={"Disable Gas Fee"} 
                        className="w-1/2" 
                        style={{border: TEN_PERCENT_BORDER}} 
                        icon={Info}
                        tooltip={"Disable charging fee when executing transactions."}
                        >
                        <CheckedInput
                            placeholder="Enabled"
                            value={metaData.is_gas_fee_disabled}
                            name="is_gas_fee_disabled"
                            onChange={handleInputChange}
                        />
                    </TextLeftInput>
                </div>
            </div>
            <div className="flex justify-between items-center w-full mt-8 gap-4">
                <div className="flex justify-between items-end w-full">
                    <TextLeftInput 
                        text={"Auto Mining"} 
                        className="w-5/12 2xl:w-2/5" 
                        style={{border: TEN_PERCENT_BORDER}} 
                        icon={Info}
                        tooltip={"Disable auto and interval mining, and mine on demand instead via an endpoint. (Recomended to leave enabled)"}
                    >
                        <CheckedInput
                            placeholder="Enabled"
                            value={metaData.is_auto_mining_enabled}
                            name="is_auto_mining_enabled"
                            onChange={handleInputChange}
                        />
                    </TextLeftInput>
                    <TextLeftInput 
                        text={"Transaction Validation"} 
                        className="w-5/12 2xl:w-2/5" 
                        style={{border: TEN_PERCENT_BORDER}} 
                        icon={Info}
                        tooltip={"Disable validation when executing transactions. (Recomended to leave enabled)"}
                    >
                        <CheckedInput
                            placeholder="Enabled"
                            value={metaData.is_transaction_validation_enabled}
                            name="is_transaction_validation_enabled"
                            onChange={handleInputChange}
                        />
                    </TextLeftInput>
                </div>
            </div>
            <div>
                <div className="flex justify-between items-end w-full mt-8 gap-4">
                    <TitleInput 
                        Title={"Chain ID"} 
                        logo={Link} 
                        placeholder={"KATANA by default"}
                        style={{border: TEN_PERCENT_BORDER}} 
                        name="chain_id"
                        value={metaData.chain_id}
                        onChange={handleInputChange}
                        className="w-1/5" 
                    >
                    </TitleInput>
                    <TitleInput
                        Title={"Seed"} 
                        logo={Plant} 
                        placeholder={"0 by default"} 
                        style={{border: TEN_PERCENT_BORDER}}
                        name="seed"
                        value={metaData.seed}
                        onChange={handleInputChange}
                        className="w-1/5" 
                     ></TitleInput>
                    <TitleInput
                        Title={"Accounts"} 
                        logo={UserList} 
                        placeholder={"10 by default"} 
                        style={{border: TEN_PERCENT_BORDER}}
                        name="accounts"
                        value={metaData.accounts}
                        onChange={handleInputChange}
                        className="w-1/5" 
                     ></TitleInput>
                </div>
            </div>
            
        </div>
    )
}

export default AnvilSpecs