import React, { useState, useEffect } from "react";
import AnimatedContainer from "../AnimatedContainer";
import { useAddChain, useConnect } from "@starknet-react/core";
import { FaWallet } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { shortString } from 'starknet';

const ConnectedAccountUI = ({
  address,
  balance,
  onRefreshBalance,
}) => {
  return (
    <div className="w-full max-w-md mx-auto bg-gray-900 rounded-lg shadow-md p-6 text-white">
      <div className="flex items-center mb-4">
        <FaWallet className="text-green-400 mr-2 text-xl" />
        <h2 className="text-xl font-semibold">Account Deployed</h2>
      </div>
      <p className="text-sm text-gray-400">Connected Address:</p>
      <div className="text-md font-mono truncate">{address}</div>

      <p className="text-sm text-gray-400 mt-4">Balance:</p>
      <div className="text-lg font-semibold">ETH {balance}</div>

      <button
        onClick={onRefreshBalance}
        className="mt-4 w-full bg-blue-600 hover:bg-blue-700 rounded-lg px-4 py-2 text-sm font-medium"
      >
        Refresh Balance
      </button>
    </div>
  );
};

const DeployAccountUI = ({
  address,
  deploying,
  error,
}) => {
  return (
    <div className="w-full max-w-md mx-auto bg-gray-900 rounded-lg shadow-md p-6 text-white">
      <div className="flex items-center mb-4">
        <IoWarning className="text-yellow-400 mr-2 text-xl" />
        <h2 className="text-xl font-semibold">Account Not Deployed</h2>
      </div>
      <p className="text-sm text-gray-400">Connected Address:</p>
      <div className="text-md font-mono truncate mb-4">{address}</div>
      <p className="text-sm text-gray-400">Please deploy your account through the wallet interface.</p>
      {error && (
        <div className="mt-3 text-red-400 text-sm">
          <strong>Error:</strong> {error}
        </div>
      )}
    </div>
  );
};


export const DeployedCheckComponent = ({
  account,
  address,
  balance,
  fetchBalance,
}) => {
  const [isDeployed, setIsDeployed] = useState(null); // null = unknown, true/false once checked
  const [deploying, setDeploying] = useState(false);
  const [error, setError] = useState("");

  async function checkIfDeployed(walletAccount) {
    try {
      const provider = walletAccount.walletProvider.provider; // The provider from the wallet account
      const address = walletAccount.address;
      // Attempt to fetch the class hash
      const classHash = await provider.getClassHashAt(address);
      console.log(classHash)
      if (classHash && classHash !== "0x0") {
        console.log("Account is deployed.");
        return true;
      } else {
        console.log("Account is not deployed.");
        return false;
      }
    } catch (err) {
      console.log(err.message)
      if (err.message.includes("Contract not found")) {
        console.log("Account is not deployed.");
        return false;
      }
      console.error("Error checking deployment status:", err);
      throw err; // Re-throw other unexpected errors
    }
  }
  

  // Check if account is deployed on mount
  useEffect(() => {
    let isMounted = true;
    async function checkDeployment() {
      if (!account || !account.walletProvider || !account.address) return;
      try {
        const deployed = await checkIfDeployed(account) 
        if (isMounted) setIsDeployed(deployed);
      } catch (err) {
        console.error("Error checking deployment:", err);
        if (isMounted) setError(err.message || "Unable to check deployment.");
      }
    }
    checkDeployment();
    return () => {
      isMounted = false;
    };
  }, [account, account.walletProvider, account.address]);

  if (isDeployed === null) {
    // We haven't finished checking yet
    return (
      <div className="w-full max-w-md mx-auto bg-gray-900 rounded-lg shadow-md p-6 text-white text-center">
        Checking account deployment status...
      </div>
    );
  }

  if (!isDeployed) {
    // Show the "not deployed" UI
    return (
      <DeployAccountUI
        address={address}
        deploying={deploying}
        error={error}
      />
    );
  }

  // If deployed, show the connected UI
  return (
    <ConnectedAccountUI
      address={address}
      balance={balance}
      onRefreshBalance={() => fetchBalance(address)}
    />
  );
};


export const CryptoConnect = ({ setIsConnecting }) => {
  const { connectAsync, connectors } = useConnect();

  const makeConnection = async (connector) => {
    try {
      setIsConnecting(true);
      await connectAsync({ connector });
    } catch (err) {
      console.error("Connection failed:", err);
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <ul>
      {connectors.map((connector) => (
        <li key={connector.id} className="mb-4">
          <div className="flex items-center">
            <img
              src={connector.icon}
              alt={`${connector.name} icon`}
              className="mr-4 w-10 h-10"
            />
            <button
              onClick={() => makeConnection(connector)}
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors duration-200"
            >
              Connect
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};

const AddChainComponent = ({
  appchain,
  chainData,
  address,
  status,
  balance,
  account,
  fetchBalance,
}) => {
  const [error, setError] = useState(null);
  const [isChainCorrect, setIsChainCorrect] = useState(false);

  // We can still use this if we want to let them add a custom chain, but if you
  // only support manual switching, you might omit or simplify this.
  const { addChainAsync, error: addChainError } = useAddChain({
    params: chainData,
  });

  /**
   * Checks if user is on the desired chain. If so, fetch balance.
   */
  const checkWalletChainId = async () => {
    try {
      const starknet = window.starknet;
      if (!starknet) {
        setError("StarkNet wallet is not installed.");
        return false;
      }
      await starknet.enable();

      const currentChainId = starknet.chainId;      // e.g. "SN_GOERLI"
      const desiredChainId = chainData.chain_id;    // e.g. "SN_GOERLI"
      if (currentChainId === desiredChainId) {
        setIsChainCorrect(true);
        // Optionally fetch balance
        if (address) {
          fetchBalance(address);
        }
        return true;
      } else {
        console.warn(
          "Not connected to the desired chain:", desiredChainId
        );
        setIsChainCorrect(false);
        return false;
      }
    } catch (error) {
      console.error("Error checking wallet chain ID:", error);
      return false;
    }
  };

  /**
   * This effect runs once to see if the user is already on the correct chain.
   */
  useEffect(() => {
    if (appchain.is_deployed) {
      checkWalletChainId();
    }
  }, [appchain.is_deployed, address]);


  if (status === "connected" && address && isChainCorrect) {
    return (
      <DeployedCheckComponent
        account={account}        // from your starknet-react or custom usage
        address={address}
        balance={balance}
        fetchBalance={fetchBalance}
      />
    );
  }

  const handleAddChain = async () => {
    try {
      await addChainAsync();
      console.log("Chain added successfully. Now switch manually in the wallet.");
    } catch (err) {
      console.error("Add chain failed:", err);
      setError(err.message);
    }
  };

  // If we’re connected but on the wrong chain, or chain not verified yet:
  if (status === "connected" && address && !isChainCorrect) {
    return (
      <div className="space-y-4">
        <p className="text-white">
          You are <strong>not</strong> on the correct chain:
          <span className="mx-2 px-2 py-1 bg-red-600 text-white rounded">
            {chainData.chain_id}
          </span>
        </p>
        <p className="text-white">
          Please <strong>manually switch</strong> in your wallet (Argent X, Braavos, etc.). 
          If you have not added the network you can add it below.
        </p>
        <button
          onClick={handleAddChain}
          className="px-4 py-2 mt-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors duration-200"
        >
          Add Chain
        </button>
        {addChainError && (
          <p className="text-red-500 text-sm mt-2">
            {addChainError.message}
          </p>
        )}

        {error && (
          <p className="text-red-500 text-sm mt-2">
            {error}
          </p>
        )}
      </div>
    );
  }

  // If we’re not connected at all (handled in parent, but just in case)
  return null;
};

const AddChainStarknetChain = ({
  appChain,
  chainData,
  accountAddress,
  status,
  balance,
  fetchBalance,
  account,
}) => {
  const [ isConnecting, setIsConnecting ] = useState(false)
  return (
    <AnimatedContainer delay={0.2}>
      <div className="p-6 bg-[#0A0A22] border border-white/10 rounded-lg h-auto">
        <h2 className="text-2xl font-light text-white mb-4">
          Wallet Configuration
        </h2>

        {/* 1) If the chain is not deployed => just inform the user */}
        {!appChain.is_deployed ? (
          <p>Waiting for Appchain deployment...</p>
        ) : (
          // 2) If wallet is "connected" and we have an address => show chain logic
          status === "connected" && accountAddress ? (
            <AddChainComponent
              appchain={appChain}
              address={accountAddress}
              chainData={chainData}
              status={status}
              balance={balance}
              account={account}
              fetchBalance={fetchBalance}
            />
          ) : 
            isConnecting ?
            <p>Connecting...</p>
            :
          (
            // 3) Otherwise => we are not connected => show wallet selection
            <CryptoConnect setIsConnecting={setIsConnecting}/>
          )
        )}
      </div>
    </AnimatedContainer>
  );
};

export default AddChainStarknetChain;
