import React, { useState, useEffect } from "react";
import { FaTelegram, FaDiscord } from "react-icons/fa";
import { motion } from "framer-motion";
import PrimaryButton from "components/PrimaryButton";
import waitlistService from "services/billing/waitlist";
import {toast, ToastContainer} from "react-toastify";

const questions = [
  {
    id: 1,
    text: "What is your primary use case for our service?",
    options: ["Development", "Testing", "Production", "Other"],
  },
  {
    id: 2,
    text: "What size is your team?",
    options: ["1-5", "6-20", "21-50", "50+"],
  },
  {
    id: 3,
    text: "What is your preferred support channel?",
    options: ["Email", "Chat", "Forum", "Phone"],
  },
];

const WaitlistPage = () => {
  const [state, setState] = useState({
    loading: false,
    hasSubmitted: false,
    error: "",
    isOnWaitlist: false,
  });

  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});

  const updateState = (updates) => setState((prev) => ({ ...prev, ...updates }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateState({ loading: true, error: "" });
  
    try {
      await waitlistService.joinWaitlist();
      updateState({ hasSubmitted: true, isOnWaitlist: true });
      toast.success("🎉 You’ve successfully joined the waitlist!", {
        position: "top-right",
        autoClose: 5000, // Auto close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch {
      updateState({ error: "Something went wrong. Please try again later." });
      toast.error("❌ Something went wrong. Please try again!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      updateState({ loading: false });
    }
  };  

  const handleAnswer = (option) => {
    setAnswers((prev) => ({
      ...prev,
      [questions[questionIndex].id]: option,
    }));
    if (questionIndex < questions.length - 1) {
      setQuestionIndex(questionIndex + 1);
    }
  };

  const handleBack = () => {
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
    }
  };

  const handleFinalSubmit = () => {
    console.log("Final Answers:", answers);
    // Handle final answers submission here.
  };

  useEffect(() => {
    async function checkWaitlist() {
      try {
        const isOnWaitlist = await waitlistService.checkWaitlist();
        updateState({ isOnWaitlist });
      } catch {
        updateState({ error: "Failed to check waitlist status. Please refresh." });
      }
    }
    checkWaitlist();
  }, []);

  const renderQuestions = () => (
    <div>
      <h2 className="text-2xl font-bold mb-4">{questions[questionIndex].text}</h2>
      <div className="grid grid-cols-2 gap-4 mb-6">
        {questions[questionIndex].options.map((option, idx) => (
          <button
            key={idx}
            onClick={() => handleAnswer(option)}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
          >
            {option}
          </button>
        ))}
      </div>
      <div className="flex justify-between">
        {questionIndex > 0 && (
          <button
            onClick={handleBack}
            className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded-lg"
          >
            Back
          </button>
        )}
        {questionIndex === questions.length - 1 && (
          <button
            onClick={handleFinalSubmit}
            className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg"
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );

  console.log(state)

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#020215] via-[#111135] to-[#0A0A22] text-white flex flex-col items-center justify-center px-6 py-8">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="w-full max-w-2xl bg-[#1A1A3A] border border-[#333355] shadow-xl rounded-lg p-8"
      >
        <ToastContainer />
        <HeaderSection />
        <SocialLinksSection />
        {!state.isOnWaitlist ? (
          <FormSection state={state} onSubmit={handleSubmit} />
        ) : (
          <SuccessMessage />
        )}
      </motion.div>
    </div>
  );
};

const HeaderSection = () => (
  <div className="text-center mb-8">
    <h1 className="text-4xl font-bold mb-4">Join Our Waitlist</h1>
    <p className="text-gray-400 text-lg">
      Be the first to experience the future of Appchain as a Service. Join now and secure your spot!
    </p>
  </div>
);

const SocialLinksSection = () => (
  <div className="flex justify-center gap-6 mb-8">
    <SocialLink
      href="https://t.me/+lZdhK9AJd2UzMmZh"
      icon={FaTelegram}
      label="Join Telegram"
    />
    <SocialLink
      href="https://discord.gg/5BSWHGnpFA"
      icon={FaDiscord}
      label="Join Discord"
    />
  </div>
);

const SocialLink = ({ href, icon: Icon, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center gap-2 px-5 py-3 bg-[#232347] hover:bg-[#2D2D56] rounded-lg text-[#FFC857] font-medium transition"
  >
    <Icon className="text-xl" />
    {label}
  </a>
);

const FormSection = ({ state, onSubmit }) => (
  <form onSubmit={onSubmit} className="space-y-6">
    {state.error && <FeedbackMessage type="error">{state.error}</FeedbackMessage>}
    <PrimaryButton
      type="submit"
      className={`w-full h-12 rounded-lg font-semibold transition ${
        state.loading ? "opacity-50 cursor-not-allowed" : ""
      }`}
      disabled={state.loading}
    >
      {state.loading ? "Joining Waitlist..." : "Join Waitlist"}
    </PrimaryButton>
  </form>
);

const FeedbackMessage = ({ type, children }) => {
  const color = type === "error" ? "text-red-500" : "text-green-500";
  return <p className={`text-sm ${color} mt-2`}>{children}</p>;
};

const SuccessMessage = () => (
  <div className="text-center">
    <h2 className="text-2xl font-semibold text-green-500 mb-4">
      You’re on the Waitlist!
    </h2>
    <p className="text-gray-400">
      Thanks for joining! Join our Discord and Telegram to stay up to date with the latest news.
    </p>
  </div>
);

export default WaitlistPage;
