import { CheckedInput, TitleInput, TextLeftInput } from "components/deployments/LaunchInputs";
import Timer from "../../../imgs/icons/Timer.svg";
import Link from "../../../imgs/icons/Link.svg";
import Info from "../../../imgs/icons/Info.svg";
import { TEN_PERCENT_BORDER } from "../../styles/styles";

const MadaraSpecs = ({ metaData, setMetaData }) => {
    const handleInputChange = (key, value) => {
        setMetaData({
            ...metaData,
            [key]: value,
        });
    };

    return (
        <div className="bg-dark rounded-lg shadow-lg">
            {/* Row 1: Block Time and Disable Gas Fee */}
            <div className="flex flex-wrap items-center gap-6">
                <div className="flex-1">
                    <TitleInput
                        Title={"Block Time (seconds)"}
                        logo={Timer}
                        placeholder={"(seconds)"}
                        style={{border: TEN_PERCENT_BORDER}}
                        name="block_time"
                        value={metaData.block_time}
                        onChange={handleInputChange}
                        readOnly={false}
                    />
                </div>
                <div className="flex-1">
                    <TextLeftInput
                        className={"mt-2"}
                        text={"Disable Gas Fee"}
                        style={{border: TEN_PERCENT_BORDER}}
                        icon={Info}
                        tooltip={"Disable charging fee when executing transactions."}
                    >
                        <CheckedInput
                            placeholder="Enabled"
                            value={metaData.is_gas_fee_disabled}
                            name="is_gas_fee_disabled"
                            onChange={handleInputChange}
                        />
                    </TextLeftInput>
                </div>
            </div>

            {/* Row 2: Chain ID and Chain Name */}
            <div className="flex flex-wrap items-end gap-6">
                <div className="flex-1">
                    <TitleInput
                        Title={"Chain ID"}
                        logo={Link}
                        placeholder={"MADARA by default"}
                        style={{border: TEN_PERCENT_BORDER}}
                        name="chain_id"
                        value={metaData.chain_id}
                        onChange={handleInputChange}
                        readOnly={true}
                    />
                </div>
                <div className="flex-1">
                    <TitleInput
                        Title={"Chain Name"}
                        logo={Link}
                        placeholder={"MADARA by default"}
                        style={{border: TEN_PERCENT_BORDER}}
                        name="chain_name"
                        value={metaData.chain_name}
                        onChange={handleInputChange}
                        readOnly={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default MadaraSpecs;
