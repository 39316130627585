import React, { useState } from 'react';
import { Badge, Button } from '@tremor/react';
import { IoMdEye, IoMdCopy } from "react-icons/io";
import { FiPause, FiPlay } from "react-icons/fi";
import { FaRegTrashAlt, FaEllipsisV } from "react-icons/fa";
import { MdRestartAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getStatusColor } from "./utils/appchainStatus";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications
import { DeployableTool } from "./DeployableTool";
import handleCopyUrl from 'utils/copyUrl';
import openUrlInNewTab from 'utils/openUrl';
import { getAppchainService } from "../../factories/deployments/appchainServiceFactory";
import ConfirmModal from '../ConfirmModal';
import { motion, AnimatePresence } from 'framer-motion';
import { DropdownButton } from '../ui/DropdownButton';
import getAppchainLogo from 'imgs/appchainLogo';

import blockExplorerLogo from "imgs/icons/block_explorer.svg"
import bridgeLogo from "imgs/icons/bridge.svg"
import indexer from "imgs/icons/indexer.svg"
import walletLogo from "imgs/icons/wallet.svg"

const TOOL_IMG_MAP = {
  'torii': indexer,
  'quasm-compass': blockExplorerLogo,
  'token-bridge': bridgeLogo,
  "block-scout": blockExplorerLogo,
  "wallet": walletLogo
};

const DetailedAppchainCard = ({ appChain, setAppChains, icon, onDelete, onAction, fetchAppChains }) => {

  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const navigateTo = (path) => navigate(`/projects/${appChain.project}/appchain/${appChain.id}${path}`);

  const handleAction = async (action) => {
    try {
      await getAppchainService(appChain.appchain_type)[action](appChain.project, appChain.id);
      onAction();
      await fetchAppChains()
    } catch (error) {
      console.error('Error during handleAction:', error);
    }
  };

  return (
    <div className='bg-[#0B0B20] rounded-xl p-6 border border-white/10 space-y-6 shadow-lg'>
      <ToastContainer />
      <div className='flex justify-between items-center'>
        <div className='flex items-center gap-4'>
          <Badge color={getStatusColor(appChain)} icon={icon}>
            {appChain.is_deployed && !appChain.is_error ? appChain.status :
              appChain.is_error ? 'Error during deployment. Contact Support' : 'Deploying ...'}
          </Badge>
        </div>
        {!appChain.is_deployed && !appChain.is_error && (
            <div className='flex items-center w-2/3 ml-4'>
              <span className='mr-2 text-gray-500'>Progress:</span>
              <div className='relative w-full h-4 bg-[#1C1C3A] rounded'>
                <div
                  className='absolute h-4 bg-green-500 rounded transition-width duration-300 ease-in-out'
                  style={{ width: `${appChain.deployment_progress}%` }}
                />
              </div>
              <span className='ml-2 text-gray-500'>{appChain.deployment_progress}%</span>
            </div>
        )}

        {appChain.is_deployed && (
          <div className='relative'>
            <Button
              className='w-10 h-10 flex items-center justify-center rounded-full bg-[#1C1C3A] hover:bg-[#29294b] transition-all duration-200 ease-in-out'
              onClick={() => setShowDropdown(!showDropdown)}
              aria-label="Options"
            >
              <FaEllipsisV />
            </Button>
            <AnimatePresence>
              {showDropdown && (
                <motion.div
                  className='absolute right-0 mt-2 bg-[#0F0F2D] border border-white/10 rounded-lg shadow-xl p-3 z-50'
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  {appChain.appchain_type !== 'madara' && (
                    <DropdownButton onClick={() => navigateTo('')} icon={IoMdEye}>
                      View
                    </DropdownButton>
                  )}
                  {!appChain.is_starting && !appChain.is_stopping && (
                    <>
                      <DropdownButton onClick={() => handleAction('restart')} icon={MdRestartAlt}>
                        Restart
                      </DropdownButton>
                      <DropdownButton
                        onClick={() => handleAction(appChain.is_started ? 'stop' : 'start')}
                        icon={appChain.is_started ? FiPause : FiPlay}
                      >
                        {appChain.is_started ? 'Pause' : 'Start'}
                      </DropdownButton>
                    </>
                  )}
                  <DropdownButton onClick={() => navigateTo('/logs')} icon={IoMdEye}>Logs</DropdownButton>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </div>

      <AppchainUrlComponent appChain={appChain} />

      <div className='space-y-4'>
        {appChain.tools.map((tool) => (
          <DeployableTool appchain={appChain} tool={tool} toolImg={TOOL_IMG_MAP[tool.identifier]} fetchAppChains={fetchAppChains} />
        ))}
      </div>

      {appChain.is_deployed && (
        <div className='flex space-x-4'>
          <ActionButton onClick={() => navigateTo('')} icon={IoMdEye}>Detail</ActionButton>
          <ActionButton onClick={() => navigateTo('/logs')} icon={IoMdEye}>Logs</ActionButton>
        </div>
      )}
    </div>
  );
};

const ActionButton = ({ onClick, icon: Icon, children }) => (
  <button
    onClick={onClick}
    className="flex-1 h-12 bg-[#1C1C3A] text-white border border-white/10 text-base rounded hover:bg-[#29294b] flex items-center justify-center gap-2 shadow-md"
    aria-label={children}
  >
    <Icon className="text-lg" />
    {children}
  </button>
);

const AppchainUrlComponent = ({ appChain }) => (
  <div className='space-y-4'>
    <div className='flex items-center gap-4'>
      <img
        src={getAppchainLogo(appChain.appchain_type)}
        className="rounded-full h-8 w-8"
        alt={`${appChain.name} logo`}
      />
      <h2 className='text-2xl font-semibold text-white'>{appChain.name}</h2>
    </div>
    {appChain.is_deployed && (
      <div className='flex flex-col gap-3'>
        <div className='flex items-center gap-2'>
          <span className="text-sm text-gray-300">Appchain RPC:</span>
          <span
            onClick={() => openUrlInNewTab(appChain.full_url)}
            className="text-sm text-blue-400 hover:text-blue-500 underline cursor-pointer"
          >
            {appChain.full_url}
          </span>
          <button
            onClick={() => handleCopyUrl(appChain.full_url)}
            className="p-2 bg-gray-800 rounded hover:bg-gray-700 transition"
            aria-label="Copy URL"
          >
            <IoMdCopy />
          </button>
        </div>
        {appChain.full_settlement_layer_url && (
          <div className='flex items-center gap-2'>
            <span className="text-sm text-gray-300">Settlement Layer:</span>
            <span
              onClick={() => openUrlInNewTab(appChain.full_settlement_layer_url)}
              className="text-sm text-blue-400 hover:text-blue-500 underline cursor-pointer"
            >
              {appChain.full_settlement_layer_url}
            </span>
            <button
              onClick={() => handleCopyUrl(appChain.full_settlement_layer_url)}
              className="p-2 bg-gray-800 rounded hover:bg-gray-700 transition"
              aria-label="Copy URL"
            >
              <IoMdCopy />
            </button>
          </div>
        )}
      </div>
    )}
  </div>
);

export default DetailedAppchainCard;
