import React, { useState } from 'react';

const DeleteArchiveProject = ({ onRequestClose, projectId, action, handleCancelSubscription,  handleDeleteProject}) => {
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (action === "cancel"){
        await handleCancelSubscription()
      }else {
        await handleDeleteProject()
      }
      onRequestClose();
    } catch (err) {
      console.log(err)
      setError(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="appChain" className="block text-gray-100 text-sm font-normal mb-2 text-center">
        Are you sure you want to {action === "delete" ? "delete" : "cancel the subscription for" } this project?
      </label>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={onRequestClose}
          className="bg-gray-500 hover:bg-gray-700 text-white font-normal py-2 px-4 mx-3 rounded focus:outline-none focus:shadow-outline"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-normal py-2 px-4 mx-3 rounded focus:outline-none focus:shadow-outline"
        >
          Confirm
        </button>
      </div>
    </form>
  );
};

export default DeleteArchiveProject;
