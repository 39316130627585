import React from 'react';
import {Link, useParams} from "react-router-dom";
import { motion } from 'framer-motion';
import useAppchains from "../../hooks/deployments/useappchains";
import AnimatedContainer from '../../components/AnimatedContainer';
import AnimatedSkeleton from '../../components/AnimatedSkeleton';
import ProjectSettingsButton from "../../components/deployments/ProjectSettingsButton";
import AppchainsContainer from 'containers/AppChains';
import LogsDisplay from '../../components/deployments/LogsDisplay';
import StarknetConfigContainer from 'containers/StarknetConfigContainer';
import EthereumConfigContainer from 'containers/EthereumConfigContainer';
import AztecConfigContainer from 'containers/AztecConfigContainer';
import ProjectStripeSubscription from "../../components/billing/ProjectStripeSubscription";
import BackButton from "../../components/ui/BackButton";
import {FaCheckCircle} from "react-icons/fa";

const ProjectDetail = () => {
  const { projectId } = useParams();
  const { project, loading, appChains, setAppChains, fetchAppChains } = useAppchains(projectId);
  const appChain = appChains.length > 0 ? appChains[0] : null;

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white flex flex-col px-6 md:px-12 lg:px-16 py-10">
        <h2 className="text-lg text-center font-semibold mb-6 text-white/80">Loading your project details...</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(3)].map((_, index) => (
            <AnimatedSkeleton key={index} className="h-[320px] rounded-lg bg-gray-700/20 animate-pulse" />
          ))}
        </div>
      </div>
    );
  }

  if (!project) {
    return (
      <div className="min-h-screen flex items-center justify-center text-center">
        <div className="space-y-4">
          <p className="text-white text-xl font-semibold">Oops! Project not found.</p>
          <BackButton />
        </div>
      </div>
    );
  }

  return (
    <AnimatedContainer>
      <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white p-6 md:p-12">
        <div className="flex items-center justify-between my-6 space-x-4">
          <BackButton />
          <Header title={project?.name || "Project Name"} />
          <ProjectSettingsButton projectId={projectId} />
        </div>

        {!project.is_subscribed && (
          <div className="mb-6">
            <SubscriptionCTA projectId={projectId} appchain={appChain}/>
          </div>
        )}

        <div
          className={`grid grid-cols-1 lg:grid-cols-2 gap-6 mt-8 ${
            !project.is_subscribed ? "blur-sm pointer-events-none opacity-60" : "opacity-100"
          } transition-opacity duration-300`}
        >
          <AppchainsContainer appChains={appChains} setAppChains={setAppChains} fetchAppChains={fetchAppChains} />
          {appChain && <ConfigContainer appChain={appChain} />}
        </div>

        <div
          className={`grid cols-1 gap-6 mt-10 ${
            !project.is_subscribed ? "blur-sm pointer-events-none opacity-60" : "opacity-100"
          } transition-opacity duration-300`}
        >
          <LogsDisplay appChain={appChain} />
        </div>
      </div>
    </AnimatedContainer>
  );
};

const Header = ({ title }) => (
    <div className="flex-grow">
        <motion.h1
            className="text-2xl lg:text-4xl font-semibold"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
        >
            {title}
        </motion.h1>
    </div>
);

const SubscriptionCTA = ({ projectId, appchain }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="rounded-lg p-6 shadow-xl max-w-7xl mx-auto"
    >
        <h2 className="text-2xl font-extrabold text-center text-white mb-4 tracking-tight">
            Find the Right Plan for You
        </h2>
        <p className="text-center text-gray-300 mb-4 max-w-2xl mx-auto text-sm">
            Choose the plan that best suits your appchain needs and unlock advanced features.
        </p>

        {/* Plans Container */}
        <div className="flex flex-col lg:flex-row justify-center gap-4">
            {appchain && appchain.tools.length === 0 &&
                <PricingCard
                    title="Standard Plan"
                    price="$49.99/month"
                    features={[
                        'Appchain',
                        'Pre-funded deployed accounts',
                        'Faucet',
                        'Advanced Logging',
                        '24/7 Support'
                    ]}
                    projectId={projectId}
                    buttonText="Get Started"
                />}
            <PricingCard
                title="Pro Plan"
                price="$79.99/month"
                features={[
                    'All Standard Plan Features',
                    'Custom Block Explorer',
                    'Bridge',
                    'Torii',
                    'Unlimited Users',
                    'Priority Support 24/7'
                ]}
                isPopular
                projectId={projectId}
                buttonText="Go Pro"
            />
        </div>

        <div className="text-center mt-6">
            <Link
                to={process.env.REACT_APP_BOOK_CALL_LINK}
                target="_blank"
                className="inline-flex items-center px-3 py-1.5 bg-white text-dark-bg rounded-full shadow-md hover:bg-gray-200"
            >
                Need Help Choosing? Let's talk
            </Link>
        </div>
    </motion.div>
);

const PricingCard = ({ title, price, features, isPopular, buttonText, projectId }) => (
    <div
        className={`bg-[#2A2A3C] p-6 rounded-lg shadow-lg border transition-transform transform hover:scale-105 max-h-[460px] ${
            isPopular ? 'border-yellow-500 ring-1 ring-yellow-500' : 'border-gray-700'
        } flex flex-col items-center text-white w-full max-w-sm`}
    >
        {isPopular && (
            <div className="bg-yellow-500 text-black text-xs font-semibold uppercase px-3 py-1 rounded-full mb-3 tracking-wide shadow-md">
                Best Value
            </div>
        )}
        <h3 className="text-xl font-bold">{title}</h3>
        <p className="text-3xl font-bold my-3">{price}</p>

        {/* Feature list with icons */}
        <ul className="text-sm text-gray-300 space-y-2 mb-4 max-h-[180px] overflow-y-auto">
            {features.map((feature, index) => (
                <li key={index} className="flex items-center">
                    <FaCheckCircle className="text-green-400 mr-2" />
                    <span>{feature}</span>
                </li>
            ))}
        </ul>

        <ProjectStripeSubscription projectId={projectId} isUpgrade={isPopular} text={buttonText} />

        {/* <div className="mt-3 text-xs text-gray-400 italic">
            <FaShieldAlt className="inline-block mr-1 text-green-300" />
            10-day money-back guarantee
        </div> */}
    </div>
);




const ConfigContainer = ({ appChain }) => {
    switch (appChain.virtual_machine_type_display) {
        case "EVM":
        case "ZKEVM":
            return <EthereumConfigContainer appChain={appChain} />;
        case "CVM":
            return <StarknetConfigContainer appChain={appChain} />;
        default:
            return <AztecConfigContainer appChain={appChain} />;
    }
};

export default ProjectDetail;
