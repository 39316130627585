import React from 'react';

const LoadingScreen = () => {
  return (
    <div className="flex items-center justify-center min-h-screen text-white">
      <div className="flex flex-col items-center space-y-6">
        {/* Loader Animation */}
        <div className="flex justify-center items-center space-x-2">
          <div className="w-6 h-6 bg-white rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
          <div className="w-6 h-6 bg-white rounded-full animate-bounce" style={{ animationDelay: '200ms' }}></div>
          <div className="w-6 h-6 bg-white rounded-full animate-bounce" style={{ animationDelay: '400ms' }}></div>
        </div>

        {/* Informative Text */}
        <div className="text-center">
          <h2 className="text-2xl font-bold">Loading...</h2>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
