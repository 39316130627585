import React, {useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import paymentService from "services/billing/payment";
import {motion} from "framer-motion";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ProjectStripeSubscription = (
    {projectId, text = "Deploy my Project", isUpgrade = false}
) => {
    const [isLoading, setIsLoading] = useState(false);

    const handlePayment = async () => {
        setIsLoading(true);

        try {
            const data = await paymentService.newProjectCheckoutSession(projectId, isUpgrade);
            const sessionId = data.session_id;
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({sessionId});
            setIsLoading(false);
        } catch (error) {
            console.error("Payment failed", error);
            setIsLoading(false);
        }
    };

    return (
        <motion.button
            whileHover={{scale: 1.05}}
            whileTap={{scale: 0.95}}
            className="mt-4 bg-accent text-dark-bg px-6 py-2 rounded-lg font-semibold shadow-md hover:shadow-lg transition"
            onClick={handlePayment}
            disabled={isLoading}

        >
            {isLoading ? "Processing..." : text}
        </motion.button>
    );
};

export default ProjectStripeSubscription;
