import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Modal from 'react-modal';

// Components
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

// Pages - Authentication
import Login from 'pages/auth/Login';
import LoginRedirect from 'pages/auth/LoginRedirect';
import RequireAuth from 'pages/auth/RequireAuth';
import RequireEnterprise from 'pages/auth/RequireEnterprise';

// Pages - Markteplace
import MarketplaceHome from 'pages/marketplace/MarketplaceHome';

// Pages - Billing
import StripePayment from 'pages/billing/StripePayment';
import WaitlistPage from 'pages/billing/Waitlist';

import AppChainDetail from 'pages/deployments/AppChainDetail';
import LaunchAppchain from 'pages/deployments/LaunchAppchain';
import ProjectDetail from 'pages/deployments/ProjectDetail';
import ProjectsPage from 'pages/deployments/ProjectsPage';
import ProjectSettings from 'pages/deployments/ProjectSettings';

// Pages - Monitoring
import DisplayServiceLogs from 'pages/monitoring/DisplayServiceLogs';
import Monitor from 'pages/monitoring/Monitor';

// Pages - Privacy
import PrivacyPolicy from 'pages/privacy/PrivacyPolicy';
import TermsOfService from 'pages/privacy/TermsOfService';

// Pages - General
import Explore from 'pages/explore/ExploreProjects';
import SupportRequestPage from 'pages/Teams/SupportRequest';
import AvailableServicesPage from "./pages/deployments/AvailableServices";


Modal.setAppElement('#root');

function MainRoutes() {

  return (
      <RequireAuth>
        <Navbar />
        <LoginRedirect />
        <Routes>
            {/* <Route element={<RequireEnterprise/>}> */}
              <Route path="/" element={<ProjectsPage />} />
              <Route path="/launch-appchain" element={<LaunchAppchain />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/monitor" element={<Monitor />} />
              <Route path="/support" element={<SupportRequestPage />} />
              {/* <Route path="/payment-history" element={<PaymentHistory />} /> */}
              {/* <Route path="/payment" element={<StarkPayment><Payment/></StarkPayment>}/> */}
              <Route path="/projects/:projectId" element={<ProjectDetail/>}/>
              <Route path="/projects/:projectId/appchain/:appChainId" element={<AppChainDetail/>}/>
              <Route path="/pay-with-stripe/:plan" element={<StripePayment/>}/>
              <Route path={"/appchains/:appchainId/services"} element={<AvailableServicesPage/>}/>
              <Route path="/projects/:projectId/:serviceName/:serviceId/logs" element={<DisplayServiceLogs/>}/>
              <Route path="/projects/settings/:projectId" element={<ProjectSettings/>}/>
              <Route path="/marketplace" element={<MarketplaceHome />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
            {/* </Route> */}
            <Route path="/waitlist" element={<WaitlistPage />} />
        </Routes>
        <Footer />
      </RequireAuth>
  );
}

function Main() {
  const location = useLocation();

  return (
    <>
      {location.pathname === '/login' ? (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <div className="pt-16">
          <MainRoutes />
        </div>
      )}
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
}

export default App;