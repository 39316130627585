import React, { useState } from "react";
import { mainnet } from "@starknet-react/chains";
import { StarknetConfig, InjectedConnector, publicProvider } from '@starknet-react/core';
import FaucetComponent from "components/deployments/Faucet";
import AddChainStarknetChain from "components/deployments/AddChainStarknetChain";
import argentLogo from "imgs/argent.svg";
import { useAccount } from "@starknet-react/core";
import uint256ToBigNumber from "../utils/uint256BigNumber";
import erc20Abi from "../data/ERC20.json"
import { Provider, Contract, shortString } from 'starknet';
import { BigNumber } from "ethers";

const connectors = [
    new InjectedConnector({options: {id: "argentX", icon: argentLogo}})
]

export default function StarknetConfigContainer({appChain}){
    return (
        <div>
            <StarknetConfig
                chains={[mainnet]}
                connectors={connectors}
                provider={publicProvider()}
            >
                <ConfigWrapper appChain={appChain}/>
            </StarknetConfig>
        </div>
    )
}


const ConfigWrapper = ({appChain}) =>{
    const { status, account, address, chainId } = useAccount();
    console.log(chainId)
    const [balance, setBalance] = useState(null);

    const chainData = {
        id: appChain.project_name,
        chain_id: appChain.metadata.chain_id,
        chain_name: appChain.project_name,
        rpc_urls: [appChain.full_url],
        native_currency: {
          type: "ERC20",
          options: {
            address:
              "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7",
            name: "Ethereum",
            symbol: "ETH",
            decimals: 18,
          },
        },
      };

      const fetchBalance = async (userAddress) => {
        const provider = new Provider({ nodeUrl: appChain.full_url });
        const erc20Contract = new Contract(erc20Abi.abi, chainData.native_currency.options.address, provider);

        try {
          const { balance } = await erc20Contract.call('balanceOf', [userAddress]);
          const rawBalance = uint256ToBigNumber(balance);
          const { decimals } = await erc20Contract.call('decimals');
          const tokenDecimals = parseInt(decimals, 10);
          const formattedBalance = rawBalance.div(BigNumber.from(10).pow(tokenDecimals));
          setBalance(formattedBalance.toString());
        } catch (err) {
          console.error('Error fetching balance from Starknet:', err);
          return null;
        }
      };

    return (
        <>
            <FaucetComponent appChain={appChain} address={address} fetchBalance={fetchBalance}/>
            <div className="mt-4">
                <AddChainStarknetChain 
                    appChain={appChain} 
                    status={status} 
                    chainData={chainData} 
                    accountAddress={address}
                    balance={balance}
                    fetchBalance={fetchBalance}
                    account={account}
                />
            </div>
        </>
    )
}